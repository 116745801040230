import React, { useEffect, useState } from "react";
import styles from "./styles/Inconformarse.module.css";
import axios from "axios";
import Alert from "../../../reutilizable/Alert";
import { useEvaluationStore } from "../../../store/useEvaluationStore";
import { getDatosParticipante } from '../../../features/visorDeDocumentos/services/datosParticipanteService';
import Loading from "../../../reutilizable/Loading";

// Obtener la URL base desde las variables de entorno
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Concatenar el contexto y el servicio/recurso 
const API_URL_DATOS_INCONFORMARSE = `${API_BASE_URL}/api/v1/evaluacion/datos-inconformarse`;
const API_URL_POST_INCONFORMARSE = `${API_BASE_URL}/api/v1/evaluacion/inconformarse`;

function parseDate(dateString) {
  if (!dateString) return null;

  // Detectar el formato según la posición del año
  const isYearFirst = /^\d{4}-\d{2}-\d{2}$/.test(dateString); // yyyy-MM-dd

  let year, month, day;

  if (isYearFirst) {
    [year, month, day] = dateString.split('-');
  } else {
    // Formato dd-MM-yyyy
    [day, month, year] = dateString.split('-');
  }
  return { year, month, day };
}

export const Inconformarse = ({ infoAcademico, }) => {
  const [formData, setFormData] = useState({
    recibida: "",
    fecha: "",
    area: "",
    documento: null,
    nombreDocumento: ""
  });

  console.log(infoAcademico);
  const { selectedDataAcademico } = useEvaluationStore();
  const [dataAcademico, setDataAcademico] = useState({});
  const [isLoadingAcademico, setIsLoadingAcademico] = useState(true);



  const [isVisible, setIsVisible] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => { setIsVisible(false) }, 2000);
    return () => clearTimeout(timer); // Limpia el temporizador al desmontar
  }, [isVisible])

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => { setError(""); }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error]);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    axios.get(API_URL_DATOS_INCONFORMARSE, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (response.data.fechaRecibido && response.data.fechaInconformidad) {
          const recibidaParsed = parseDate(response.data.fechaRecibido);
          const inconformidadParsed = parseDate(response.data.fechaInconformidad);

          
          setFormData({
            recibida: `${recibidaParsed.year}-${recibidaParsed.month}-${recibidaParsed.day}` || "",
            fecha: `${inconformidadParsed.year}-${inconformidadParsed.month}-${inconformidadParsed.day}` || "",
            area: response.data.razones || "",
            documento: null,
            nombreDocumento: response.data.nombreArchivo,
          });
          setDataAcademico(response.data);
          setIsLoadingAcademico(false);
        } else {
          setFormData({
            recibida: "",
            fecha: "",
            area: response.data.razones || "",
            documento: null,
            nombreDocumento: "",
          });
        }

      })
      .catch(error => {
        setFormData({
          recibida: "",
          fecha: "",
          area: "",
          documento: null,
          nombreDocumento: ""
        }

        )
        console.error("Error fetching data: ", error)
      })
  }, [])


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      documento: e.target.files[0],
      nombreDocumento: e.target.files[0]?.name || ""
    }));
  };

  const validateFields = () => {
    let hasError = false;
    Object.keys(formData).forEach((key) => {
      // Skip the "documento" field from validation
      if (key === "documento") return;

      if (formData[key] === "" || formData[key] === null) {
        console.log("error en ", key);
        hasError = true;
      }
    });

    return hasError;
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    const hasError = validateFields();
    if (hasError) {
      setError("Todos los campos son obligatorios.");
    } else {
      setError("");

      let [year, month, day] = formData.recibida.split('-');
      let [yearInc, monthInc, dayInc] = formData.fecha.split('-');
      const requestData = {
        idSolicitud: infoAcademico?.id,
        filedata: formData.documento,
        fechaRecibido: `${day}-${month}-${year}`,
        fechaInconformidad: `${dayInc}-${monthInc}-${yearInc}`,
        razones: formData.area,
      };

      console.log("reqest ", requestData)
      const token = localStorage.getItem('accessToken');
      axios.post(API_URL_POST_INCONFORMARSE, requestData, {
        headers: {
          Accept: '*/*',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          setIsVisible(!isVisible);
          console.log(response.data);
        })
        .catch((error) => {
          console.error("Error uploading form ", error)
        });

    }
  };


  const handleReset = () => {
    setFormData({
      recibida: "",
      fecha: "",
      area: "",
      documento: null,
      nombreDocumento: ""
    });
  };

  if(isLoadingAcademico) return <Loading />
  return (
    <>
    {infoAcademico ? (
      <div className={styles.main}>
        <div>
          <p>{infoAcademico.nombre} {infoAcademico.apellidoPaterno} {infoAcademico.apellidoMaterno} ({infoAcademico.codigo})</p>
        </div>
        <div>
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.dateInput}>
              <div className={styles.fecha_ley}>
                <p htmlFor="recibida">Solicitud recibida en</p>
              </div>

              <input
                id="recibida"
                name="recibida"
                type="date"
                placeholder="Seleccione una fecha"
                value={formData.recibida}
                onChange={handleChange}
              />
            </div>

            <div className={styles.dateInput}>
              <div className={styles.fecha_ley}>
                <p htmlFor="fecha">Fecha inconformidad</p>
              </div>

              <input
                id="fecha"
                name="fecha"
                type="date"
                placeholder="Seleccione una fecha"
                value={formData.fecha}
                onChange={handleChange}
              />
            </div>

            <br />
            <p htmlFor="razones">Razones de la inconformidad</p>
            <textarea
              id="area"
              name="area"
              className={styles.txtArea}
              value={formData.area}
              onChange={handleChange}
            />


            <div className={styles.dateInput}>
              <p className={styles.info}>Documento que sustenta la inconformidad</p>
              <label className={styles.labelFile} htmlFor="documento">
                Examinar
              </label>
              <input
                id="documento"
                className={styles.file}
                type="file"
                onChange={handleFileChange}
              />
              <input
                id="nombreDocumento"
                name="nombreDocumento"
                type="text"
                value={formData.nombreDocumento}
                readOnly
              />
            </div>


            <div className={styles.agregar}>
              <button type="button" className={styles.btn} onClick={handleReset}>Limpiar solicitud</button>
              <button type="submit" className={styles.btn}>Registrar inconformidad</button>
            </div>

          </form>

          <p className={styles.info}><h3>Para procesar otro acad&eacute;mico, haga clic sobre su nombre en la lista de la izquierda.</h3></p>
          {isVisible && (
            <Alert typeAlert={'success'}>
              <p>Se ha guardo con éxito</p>
            </Alert>
          )}

          {error && (
            <p className={styles.error}>{error}</p>
          )
          }
        </div>
      </div>):
      (<p>No hay información para mostrar</p>)}
    </>
  );
};
