import React, { useEffect, useState } from "react";
import styles from "../styles/FiltroSubirDocumentos.module.css";
import axios from "axios";

const API_URL_DEPENDENCIAS = `${process.env.REACT_APP_API_BASE_URL}/api/v1/dependencia/admingral`;

const FiltroSubirDocumentos = ({
  searchTerm,
  handleSearchChange,
  selectedDependencia,
  handleDependenciaChange,
  cicloDisponible,
}) => {
  const [dependencias, setDependencias] = useState([]);

  useEffect(() => {
    const fetchDependencias = async () => {
      const token = localStorage.getItem("accessToken");

      try {
        const response = await axios.get(API_URL_DEPENDENCIAS, {
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        if (response.data && response.data.dependenciasADMINGRAL) {
          setDependencias(response.data.dependenciasADMINGRAL);
        }
      } catch (error) {
        console.error("Error fetching dependencias: ", error);
      }
    };

    fetchDependencias();
  }, []);

  return (
    <div className={styles.searchAndDependencia}>
      {/* <div className={styles.searchInputContainer}>
        <label htmlFor="searchInput" className={styles.searchLabel}>
          Buscar:
        </label>
        <input
          type="text"
          id="searchInput"
          className={styles.searchInput}
          placeholder="Buscar por código de profesor o nombre"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div> */}

      <div className={styles.municipioFilterContainer}>
        <label htmlFor="dependenciaSelector">Dependencia:</label>
        <select
          id="dependenciaSelector"
          value={selectedDependencia}
          onChange={handleDependenciaChange}
          disabled={!cicloDisponible}
        >
          <option value="">Seleccione una dependencia</option>
          {dependencias.map((dependencia) => (
            <option key={dependencia.id} value={dependencia.id}>
              {dependencia.dependencia}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default FiltroSubirDocumentos;
