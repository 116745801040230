import { ESTATUS } from "../models";
import Spinn from "./Spinn";

const ShowButton = ({ validStatus, downloadPDF, statusResponse, styles }) => {
  if (validStatus(ESTATUS.APROBADO)) {
    return (
      <button
        onClick={downloadPDF}
        type="button"
        className={styles.btnDownload}
        disabled={!validStatus(ESTATUS.APROBADO)}
      >
        {statusResponse.isLoading ? <Spinn /> : "Descargar"}
      </button>
    );
  }

  return (
    <button
      className={`${styles.btn} ${validStatus(ESTATUS.PENDIENTE_REVISION) && styles.btnDisabled
        }`}
      type="submit"
      disabled={validStatus(ESTATUS.PENDIENTE_REVISION)}
    >
      {statusResponse.isLoading ? <Spinn /> : "Enviar"}

    </button >
  )
};

export default ShowButton;
