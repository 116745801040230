import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import styles from "./llenarSolicitudPage.module.css";
import InputField from "../../../../reutilizable/InputField";
import { formatTimestampToDate } from "../../../../utils";
import SelectField from "../../../../reutilizable/SelectField";
import ConditionalSelectOrInput from "../../../../reutilizable/ConditionalSelectInput";
import Alert from "../../../../reutilizable/Alert";
import { useNavigate } from "react-router-dom";
import ModalidadEvaluacion from "../../expediente/components/ModalidadEvaluacion";

// Obtener la URL base desde las variables de entorno
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// Concatenar el contexto y el servicio/recurso
const API_URL_GRADO = `${API_BASE_URL}/api/v1/grado/all`;
const API_URL_AREA = `${API_BASE_URL}/api/v1/area-conocimiento/all`;
const API_URL_NIVEL = `${API_BASE_URL}/api/v1/nivel-educativo/all`;
const API_URL_NOMBRAMIENTO = `${API_BASE_URL}/api/v1/nombramiento/activa`;
const API_URL_DEPENDENCIA = `${API_BASE_URL}/api/v1/dependencia/activa`;
const API_URL_PRELLENADO = `${API_BASE_URL}/api/v1/solicitud/activa`;
const API_URL_SOLICITUD = `${API_BASE_URL}/api/v1/solicitud`;
const API_URL_PROGRAMAS_EDUCATIVOS = `${API_BASE_URL}/api/v1/programa-educativo/all`;
const API_URL_MUNICIPIOS = `${API_BASE_URL}/api/v1/municipio/all`;
const API_URL_STATUS = `${API_BASE_URL}/api/v1/solicitud/status`;

export const LlenarSolicitudComponent = () => {
  const navigate = useNavigate();

  const initialState = {
    codigo: null,
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    rfc: "",
    CURP: "",
    ultimogrado: {},
    nombregradoacademico: "",
    institucionOtorgante: "",
    correo: "",
    telefono: "",
    telefonoMovil: "",
    nacionalidad: "",
    entidadFederativa: "",
    nombramiento: null,
    dependencia: null,
    municipio: null,
    fechaDeIngreso: null,
    antiguedad: null,
    PuestoDirectivo: false,
    areaConocimiento: null,
    departamento: "",
    nombreJefeDepto: "",
    textoAclarativo: "",
    todosCamposCorrectos: "",
    cargaGlobal: [],
    nivelEducativo: {},
    fechaNacimientoFormato: ""

  };


  const [grados, setGrados] = useState([])
  const [areaEditable, setAreaEditable] = useState(false);
  const [programas, setProgramas] = useState([])
  const [participaciones, setParticipaciones] = useState([])
  const [dependencias, setDependencias] = useState([])
  const [departamentos, setDepartamentos] = useState([])
  const [nombramientos, setNombramientos] = useState([]);
  const [niveles, setNiveles] = useState([])
  const [areas, setAreas] = useState([])
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState("");
  const [total, setTotal] = useState(0.0)
  const [municipios, setMunicipios] = useState([])
  const [isVisible, setIsVisible] = useState(true);
  const [jefes, setJefes] = useState([]);
  const [status, setSatus] = useState(null)

  //status
  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    axios.get(API_URL_STATUS, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        console.log("status" ,response.data)
        setSatus(parseInt(response.data))
      })
      .catch(error => console.error("Error fetching grados: ", error))
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    axios.get(API_URL_MUNICIPIOS, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        setMunicipios(response.data)
      })
      .catch(error => console.error("Error fetching grados: ", error))
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    axios.get(API_URL_GRADO, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        setGrados(response.data)
      })
      .catch(error => console.error("Error fetching grados: ", error))
  }, [])

  
  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    axios.get(API_URL_PRELLENADO, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        const data = response.data;
        console.log("prellenado ", response.data)
        // Set form data with the pre-filled values
        setFormData((prevData) => ({
          ...prevData,
          codigo: data.codigo || null,
          nombre: data.nombre || "",
          apellidoPaterno: data.apellidoPaterno || "",
          apellidoMaterno: data.apellidoMaterno,
          rfc: data.datosSep.rfc || "",
          CURP: data.curp || "",
          ultimogrado: data.grado || null,
          nombregradoacademico: data.nombrePosgrado || "",
          institucionOtorgante: data.institucionOtorgante || "",
          correo: data.email || "",
          telefono: data.telefono || "",
          ext: data.ext || "",
          telefonoMovil: data.movil || "",
          nacionalidad: data.nacionalidad || "",
          entidadFederativa: "",
          municipio: data.municipio || null,
          fechaDeIngreso: data.fechaIngresoFormato || null,
          antiguedad: data.antiguedad || null,
          PuestoDirectivo: data.directivo || false,
          areaConocimiento: data.datosSep.idAreaConocimiento || null,
          cargaGlobal: data.cargaGlobal,
          nivelEducativo: data.datosSep.nivelEducativo || {},
          fechaNacimientoFormato: data.fechaNacimientoFormato || ""
          
        }));


        setTotal(data.cargaGlobal.reduce((total, item) => {
          return total + parseFloat(item.cargaHoraria || 0);
        }, 0))

        if (!data.datosSep.idAreaConocimiento) setAreaEditable(true)
      })
      .catch(error => {
        const status = error.response.status;

        if (status === 401) {
          setError("Usuario no autorizado")
        } else if (status === 403) {
          setError("Convocatoria no valida")
        }
      })
  }, [status])

  //programas educativos
  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    axios.get(API_URL_PROGRAMAS_EDUCATIVOS, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        setProgramas(response.data)
      })
      .catch(error => console.error("Error fetching grados: ", error))
  }, [])

  ///area-conocimiento
  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    axios.get(API_URL_AREA, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        setAreas(response.data)
      })
      .catch(error => console.error("Error fetching grados: ", error))
  }, [])

  //nivel educativo
  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    axios.get(API_URL_NIVEL, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        setNiveles(response.data)

      })
      .catch(error => console.error("Error fetching grados: ", error))
  }, [])

  //nombramiento
  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    axios.get(API_URL_NOMBRAMIENTO, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        setNombramientos(response.data)
      })
      .catch(error => console.error("Error fetching grados: ", error))
  }, [])


  //dependencias
  useEffect(() => {
    const token = localStorage.getItem('accessToken');

    axios.get(API_URL_DEPENDENCIA, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        console.log("jefes ", response.data)
        const { dependencias, departamentos, jefesDepartamento } = response.data;
        const result = departamentos.map((departamento, index) => ({
          id: index + 1,
          departamento: departamento
        }));
        const resultJefes = jefesDepartamento.map((jefe, index) => ({
          id: index + 1,
          jefe: jefe
        }));
        setJefes(resultJefes)
        setDependencias(dependencias);
        setDepartamentos(result)

      })
      .catch(error => console.error("Error fetching dependencias: ", error))
  }, [])

  // En casos de que solo regrese un dato nombramientos end point
  useEffect(() => {
    console.log("nombramientos ", nombramientos)
    if (nombramientos && nombramientos.length === 1) {
      console.log("un nombramiento ", nombramientos[0].id)
      setFormData((prevData) => ({
        ...prevData,
        nombramiento: nombramientos[0].id,
      }));
    }
  }, [nombramientos]);

  // En casos de que solo regrese un dato en dependencias
  useEffect(() => {
    if (dependencias && dependencias.length === 1) {
      setFormData((prevData) => ({
        ...prevData,
        dependencia: dependencias[0].id,
      }));
    }
  }, [dependencias]);

  // En casos de que solo regrese un dato en dependencias
  useEffect(() => {
    if (departamentos && departamentos.length === 1) {
      setFormData((prevData) => ({
        ...prevData,
        departamento: departamentos[0].id,
      }));
    }
  }, [departamentos]);

  useEffect(() => {
    if (jefes && jefes.length === 1) {
      setFormData((prevData) => ({
        ...prevData,
        nombreJefeDepto: jefes[0].id,
      }));
    }
  }, [jefes]);

  // Manejar cambios en los inputs
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  // Validar campos
  const validateFields = () => {
    let hasError = false;
    Object.keys(formData).forEach((key) => {
      if (key !== "textoAclarativo" && key !== "todosCamposCorrectos" && (formData[key] === "" || (formData[key] === null && key !== "PuestoDirectivo"))) {
        console.log("key ", key)
        hasError = true;
      }
    });

    return hasError;
  };

  // Enviar formulario
  const handleSubmit = (e) => {
    e.preventDefault();

    const hasError = validateFields();
    if (hasError || !formData.todosCamposCorrectos) {
      setError("Todos los campos son obligatorios.");
    } else {
      setError("");
      console.log("formData.nombramiento ", formData.nombramiento)
      const requestData = {
        datosSep: {
          rfc: formData.rfc,
          idAreaConocimiento: Number(formData.areaConocimiento),
          idNivelEducativo: Number(formData.nivelEducativo.id),
        },
        idGrado: Number(formData.ultimogrado.id),
        idNombramiento: Number(formData.nombramiento),
        idDependencia: Number(formData.dependencia),
        nombre: formData.nombre,
        apellidoPaterno: formData.apellidoPaterno,
        apellidoMaterno: formData.apellidoMaterno,
        curp: formData.CURP,
        nombrePosgrado: formData.nombregradoacademico,
        institucionOtorgante: formData.institucionOtorgante,
        nombreJefeDepto: formData.nombreJefeDepto,
        email: formData.correo,
        telefono: formData.telefono,
        ext: formData.ext,
        movil: formData.telefonoMovil,
        horasFrenteGrupo: total,
        departamento: formData.departamento,
        idMunicipio: formData.municipio,
        fechaIngresoFormato: formData.fechaDeIngreso,
        antiguedad: formData.antiguedad,
        directivo: formData.PuestoDirectivo,
        nacionalidad: formData.nacionalidad,
        estadonac: formData.entidadFederativa,
        otros: formData.textoAclarativo,
        cargaGlobal: formData.cargaGlobal,
        fechaNacimientoFormato: formData.fechaNacimientoFormato



      };

      const token = localStorage.getItem('accessToken');
      axios.post(API_URL_SOLICITUD, requestData, {
        headers: {
          Accept: '*/*',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
        .then((response) => {
          window.location.reload();
          

        })
        .catch((error) => {
          const status = error.response.status;
          if (status === 403) {
            setError("No se puede almacenar más de una solicitud en la misma convocatoria")
          }
        });
    }
  };

  // Limpiar formulario
  const handleCancel = () => {
    setFormData(initialState);
    setError("");
  };

  useEffect(() => {
    const timer = setTimeout(() => { setIsVisible(false) }, 2000);
    return () => clearTimeout(timer); // Limpia el temporizador al desmontar
  }, [isVisible])

  // Efecto para limpiar los errores después de 3 segundos
  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(""); // Limpiar el mensaje de error después de 3 segundos
      }, 3000);
      return () => clearTimeout(timer); // Limpiar el timeout si el componente se desmonta
    }
  }, [error]);

  return (
    <>

      <div className={styles.solicitudContainer}>
        {status === 0 ? (<ModalidadEvaluacion setStatus={setSatus}/>) :  (
          <>
            <h2 className={styles.titulo}>PROGRAMA DE ESTIMULOS AL DESEMPEÑO DOCENTE 2023-2024</h2>
            <p> Revise que su información sea correcta antes de suscribir la solicitud.</p>

            <div className={styles.container}>
              <form className={styles.form} id="datosPersonalesForm">
                <div className={styles.form_data}>
                  <h2 className={styles.titulo}>Datos personales</h2>

                  <InputField label="Código" id="codigo" value={formData.codigo} readOnly styles={styles} />
                  <InputField label="Nombre" id="nombre" value={formData.nombre} readOnly styles={styles} />
                  <InputField label="Apellido Paterno" id="apellidoPaterno" value={formData.apellidoPaterno} readOnly styles={styles} />
                  <InputField label="Apellido Materno" id="apellidoM" value={formData.apellidoMaterno} readOnly styles={styles} />
                  <InputField label="RFC" id="rfc" value={formData.rfc} readOnly styles={styles} />
                  <InputField label="CURP" id="CURP" value={formData.CURP} readOnly styles={styles} />
                  <InputField label="Último grado" id="ultimogrado" value={formData.ultimogrado.nombre} readOnly styles={styles} />
                  <SelectField label="Área de conocimiento" id="areaConocimiento" value={formData.areaConocimiento} onChange={handleChange} options={areas} propertyName={""} readOnly={!areaEditable} styles={styles} />
                  <InputField label="Nombre del grado académico" id="nombregradoacademico" value={formData.nombregradoacademico} onChange={handleChange} styles={styles} />
                  <InputField label="Institución otorgante" id="institucionOtorgante" value={formData.institucionOtorgante} onChange={handleChange} styles={styles} />
                  <InputField label={<>Correo Electrónico<span className={styles.tooltipIcon}><FontAwesomeIcon icon={faQuestionCircle} /><span className={styles.tooltipText}>la información aquí añadida es única y exclusivamente para seguimiento de su solicitud de PROESDE, para actualizar cualqueir dato de manera definitiva favor de dirigirse a la Coordinación de Personal de su dependencia de adscripción</span></span></>} id="correo" value={formData.correo} readOnly type="email" styles={styles} />
                  <InputField label="Teléfono" id="telefono" value={formData.telefono} readOnly type="tel" styles={styles} />
                  <InputField label="Ext." id="ext" value={formData.ext} onChange={handleChange} styles={styles} />
                  <InputField label="Teléfono Móvil" id="telefonoMovil" value={formData.telefonoMovil} onChange={handleChange} type="tel" styles={styles} />
                  <InputField label="Nacionalidad" id="nacionalidad" value={formData.nacionalidad} readOnly type="text" styles={styles} />
                  <InputField label="Entidad Federativa" id="entidadFederativa" value={formData.entidadFederativa} onChange={handleChange} styles={styles} />
                  <div className={styles.inputContainer}>
                    <label htmlFor="todosCamposCorrectos">¿Estas de acuerdo con los campos?</label>
                    <select id="todosCamposCorrectos" value={formData.todosCamposCorrectos} onChange={handleChange}>
                      <option value="">Seleccione</option>
                      <option value="true">Si</option>
                      <option value="false">No</option>
                    </select>

                  </div>

                  {formData.todosCamposCorrectos === "false" && (
                    <InputField label="Todas las notificaciones de este sistema serán remitidas a este correo electrónico, en caso de que requiera actualizarse, favor de dirigirse a la Coordinación de Personal de su dependencia de adscripción" id="textoAclarativo" value={formData.textoAclarativo} onChange={handleChange} styles={styles} />
                  )}
                </div>
              </form>
                {console.log("nombramiento html", formData.nombramiento)}
              <h2 className={styles.titulo}> Datos de nombramiento o contrato actual </h2>
              <form className={styles.form} id="informacionAcademicaForm">
                <div className={styles.form_data}>
                  {/*<ConditionalSelectOrInput label="Nombramiento" id="nombramiento" value={formData.nombramiento} options={nombramientos} propertyName={"nombramiento"} onChange={handleChange} styles={styles} />*/}
                  <div className={styles.inputContainer}>
                    <label htmlFor="nombramiento">Nombramiento</label>
                    {nombramientos && nombramientos.length === 1 ? (
                      <input
                        type="text"
                        id="nombramiento"
                        value={nombramientos[0].nombramiento}
                        readOnly
                      />

                    ) : (
                      <select id="nombramiento" value={formData.nombramiento} onChange={handleChange} >
                        <option value="">Seleccione</option>
                        {nombramientos && nombramientos.length > 0 &&
                          nombramientos.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.nombramiento}
                            </option>
                          ))}
                      </select>
                    )}
                  </div>
                  <InputField label="Horas Frente Al Grupo" id="horasFrenteAlGrupo" value={total} readOnly styles={styles} />
                  <InputField label="Nivel Educativo" id="nivelEducativo" value={formData.nivelEducativo.nombre} options={niveles} readOnly styles={styles} />
                  {/*<InputField label="Nivel Educativo" id="nivelEducativo" value={formData.nivelEducativo.nombre} readOnly styles={styles} />*/}
                  <div className={styles.inputContainer}>
                    <label htmlFor="dependencia">Dependencia</label>
                    {dependencias && dependencias.length === 1 ? (
                      <input
                        type="text"
                        id="nombramiento"
                        value={dependencias[0].dependencia}
                        readOnly
                      />

                    ) : (
                      <select id="dependencia" value={formData.dependencia} onChange={handleChange} >
                        <option value="">Seleccione</option>
                        {dependencias && dependencias.length > 0 &&
                          dependencias.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.dependencia}
                            </option>
                          ))}
                      </select>
                    )}
                  </div>
                  {/*<ConditionalSelectOrInput label="Departamento" id="departamento" value={formData.departamento} options={departamentos} propertyName={""} onChange={handleChange} styles={styles} />*/}
                  <div className={styles.inputContainer}>
                    <label htmlFor="departamento">Departamentos</label>
                    {departamentos && departamentos.length === 1 ? (
                      <input
                        type="text"
                        id="departamento"
                        value={departamentos[0].departamento}
                        readOnly
                      />

                    ) : (
                      <select id="departamento" value={formData.departamento} onChange={handleChange} >
                        <option value="">Seleccione</option>
                        {departamentos && departamentos.length > 0 &&
                          departamentos.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.departamento}
                            </option>
                          ))}
                      </select>
                    )}
                  </div>

                  {/*<InputField label="Nombre del Jefe del Departamento" id="nombreJefeDepto" value={formData.nombreJefeDepto} onChange={handleChange} styles={styles} />*/}
                  <div className={styles.inputContainer}>
                    <label htmlFor="nombreJefeDepto">Jefe de Departamento</label>
                    {jefes && jefes.length === 1 ? (
                      <input
                        type="text"
                        id="nombreJefeDepto"
                        value={jefes[0].jefe}
                        readOnly
                      />

                    ) : (
                      <select id="nombreJefeDepto" value={formData.nombreJefeDepto} onChange={handleChange} >
                        <option value="">Seleccione</option>
                        {jefes && jefes.length > 0 &&
                          jefes.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.jefe}
                            </option>
                          ))}
                      </select>
                    )}
                  </div>
                  {/*<InputField label="Municipio" id="municipio" value={formData.municipio} onChange={handleChange} styles={styles} />*/}
                  <SelectField label="Municipio" id="municipio" value={formData.municipio} onChange={handleChange} options={municipios} propertyName={"municipio"} styles={styles} />
                  <InputField label="Fecha de Ingreso" id="fechaDeIngreso" value={formData.fechaDeIngreso} readOnly styles={styles} />
                  <InputField label="Antigüedad" id="antiguedad" value={formData.antiguedad} readOnly styles={styles} />
                  <div className={styles.inputContainer}>
                    <label htmlFor={"PuestoDirectivo"}>Puesto Directivo</label>
                    <input
                      id="PuestoDirectivo"
                      type="text"
                      value={formData.PuestoDirectivo === false ? "No" : "Si"}
                      onChange={handleChange}
                      readOnly
                    />
                  </div>

                  {/*Carga Global*/}
                  <h2 className={styles.titulo}>Carga Global</h2>
                  <table>
                    <thead>
                      <tr>
                        <th className={styles.tableHeader}>Nivel</th>
                        <th className={styles.tableHeader}>Carga Horaria</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formData.cargaGlobal && formData.cargaGlobal.map((item, index) => (
                        <tr key={index}>
                          <td>{item.nivel}</td>
                          <td>{item.cargaHoraria}</td>
                        </tr>
                      ))}
                      <tr className={styles.totalRow}>
                        <td>TOTAL</td>
                        <td>{total}</td>
                      </tr>
                    </tbody>
                  </table>

                  <h1 style={{ marginTop: '20px', color: 'red' }}>
                    <strong>IMPORTANTE</strong>
                  </h1>
                  <ul>
                    <li>La solicitud y la carta compromiso se genera automáticamente al hacerse el registro en la línea.</li>
                    <li>Si los datos contienen errores una vez impresos, puede acceder nuevamente al sistema y modificarlos.</li>
                    <li>El sistema generará los documentos con los datos actualizados al finalizar las modificaciones.</li>
                    <li><strong>El sistema puede demorar algunos segundos en responder.</strong></li>
                  </ul>
                </div>
              </form>

              {error && (
                <p className={styles.error}>{error}</p> // Mostrar mensaje de error general
              )
              }

              {/* SELECCIONAR LA MODALIDAD POR LA CUAL DESEA PARTICIPAR */}
              {isVisible && (
                <ModalidadEvaluacion />
              )}

              {/* {isVisible && (
    <Alert typeAlert={'success'}>
      <p>Se ha guardo con éxito</p>
    </Alert>
  )} */}

              <div className={styles.submit}>
                <button className={`${styles.btn} ${styles.enviar}`} type="submit" onClick={handleSubmit} >
                  Guardar
                  <span className={styles.iconContainer}>
                    <FontAwesomeIcon icon={faFloppyDisk} className={styles.customIcon} />
                  </span>
                </button>
                <button className={`${styles.btn} ${styles.cancelar}`} type="button" onClick={handleCancel} >
                  Cancelar
                </button>
              </div>
            </div>
          </>
        )}

      </div>

    </>
  );
};