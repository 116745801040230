import React, { useState, useEffect } from "react";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import styles from "../css/TablaCargaEstudiante.module.css";
import Loading from "../../../../reutilizable/Loading";
import useCicloDisponibilidad from "../../../../hooks/useCicloDisponibilidad";
import useCicloChange from "../../../../hooks/useCicloChange";
import FiltroCargaEstudiante from "./FiltroCargaEstudiante";
import TablaMateriasEstudianteDetalle from "./TablaMateriasEstudianteDetalle";
import useCiclosEscolares from "../../../../hooks/useCiclosEscolares";
import PaginacionEstudiante from "./PaginacionEstudiante";
import useEvaluacionEstudianteContext from "../context/useEvaluacionEstudianteContext";
import useProfesorPag from "../../../../hooks/useProfesorPag";

const TablaMateriasEstudiante = ({ totalProfesor }) => {
  const {
    municipios,
    handleMunicipioChange,
    cicloEscolar,
    setCicloEscolar,
    isLoadingProfesores,
    selectedMunicipio,
    setSelectedMunicipio,
    getListProfesores,
  } = useEvaluacionEstudianteContext();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [filteredMaterias, setFilteredMaterias] = useState([]);
  const {
    currentPage,
    totalPages,
    handleFirstPage,
    handleLastPage,
    setCurrentPage,
  } = useProfesorPag(totalProfesor);
  const { selectedCiclo } = useCicloChange(setCurrentPage);
  const { cicloDisponible } = useCicloDisponibilidad(selectedCiclo);
  const { ciclosEscolares, fetchCiclosEscolares } = useCiclosEscolares();
  const requestBodyProfesoresList = {
    codigoEmpleado: localStorage.getItem("userName"),
    cicloEscolar: cicloEscolar,
    page: "1",
    app: "PruebasSpertoDigital",
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedMaterias = [...filteredMaterias].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setFilteredMaterias(sortedMaterias);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? <FaArrowUp /> : <FaArrowDown />;
    }
    return null;
  };

  const handleChangeSelect = async (e) => {
    const newCicloEscolar = e.target.value;
    setCicloEscolar(newCicloEscolar);
    setSelectedMunicipio("");
    setCurrentPage(1);
    await getListProfesores({
      ...requestBodyProfesoresList,
      cicloEscolar: newCicloEscolar,
    });
  };

  const handleNextPageLegacy = async () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      await getListProfesores({
        ...requestBodyProfesoresList,
        page: currentPage + 1,
      });
    }
    setSelectedMunicipio("");
  };

  const handlePreviousPageLegacy = async () => {
    if (currentPage <= totalPages) {
      setCurrentPage(currentPage - 1);
      await getListProfesores({
        ...requestBodyProfesoresList,
        page: currentPage - 1,
      });
    }
    setSelectedMunicipio("");
  };

  useEffect(() => {
    if (ciclosEscolares.length === 0) {
      fetchCiclosEscolares();
    }
  }, [ciclosEscolares, fetchCiclosEscolares]);

  return (
    <div className={styles.container}>
      <div className={`${styles.filter} ${styles.marginBottom}`}>
        <label htmlFor="cicloEscolar">CICLO ESCOLAR: </label>
        <select
          id="cicloEscolar"
          value={cicloEscolar}
          onChange={handleChangeSelect}
          disabled={!cicloDisponible}
        >
          <option value="">Seleccione un ciclo escolar</option>
          {ciclosEscolares.length > 0 ? (
            ciclosEscolares.map((ciclo) => (
              <option key={ciclo} value={ciclo}>
                {ciclo}
              </option>
            ))
          ) : (
            <option value="" disabled>
              No hay ciclos disponibles
            </option>
          )}
        </select>
      </div>
      <div className={`${styles.filterRow}`}>
        <FiltroCargaEstudiante
          municipios={municipios}
          currentPage={currentPage}
          selectedMunicipio={selectedMunicipio}
          handleMunicipioChange={handleMunicipioChange}
          cicloDisponible={!!cicloEscolar}
        />
      </div>
      {cicloEscolar &&
        (isLoadingProfesores ? (
          <Loading />
        ) : (
          <TablaMateriasEstudianteDetalle
            currentPage={currentPage}
            cicloDisponible={cicloDisponible}
            getSortIcon={getSortIcon}
            handleSort={handleSort}
          />
        ))}
      <PaginacionEstudiante
        currentPage={currentPage}
        totalPages={totalPages}
        onFirstPage={handleFirstPage}
        onLastPage={handleLastPage}
        onPreviousPage={handlePreviousPageLegacy}
        onNextPage={handleNextPageLegacy}
      />
    </div>
  );
};

export default TablaMateriasEstudiante;
