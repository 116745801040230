import { useCallback, useState } from "react";
import {
  obtenerDocentes,
  obtenerCicloActual,
  obtenerSizeList,
  getReportPT,
  getDetallePT,
} from "../services/listPlanTrabajo";

const useListCargaHoraria = (setPlanTrabajo, selectedCiclo, currentPage = 1, userInfo, setFilteredPlanTrabajos) => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchTotalSize = useCallback(async () => {
    try {
      setIsLoading(true);
      const size = await obtenerSizeList(selectedCiclo, userInfo);
      if (!size) {
        console.warn("Size is empty or undefined.");
        return 0;
      }
      return size;
    } catch (error) {
      console.error("Error fetching total size:", error);
      return 0;
    } finally {
      setIsLoading(false);
    }
  }, [selectedCiclo, userInfo]);

  const fetchCicloActual = useCallback(async () => {
    try {
      setIsLoading(true);
      const ciclo = await obtenerCicloActual();
      if (!ciclo) {
        console.warn("Ciclo actual is empty or undefined.");
        return null;
      }
      return ciclo;
    } catch (error) {
      console.error("Error fetching ciclo actual:", error);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, []);

  const fetchDocentes = useCallback(async () => {
    try {
      setIsLoading(true);
      const codigoEmpleado = userInfo;
      const app = "PruebasSpertoDigital";

      const response = await obtenerDocentes(codigoEmpleado, selectedCiclo, currentPage, app);
      if (!response || response.length === 0) {
        console.warn("No docentes found.");
        setPlanTrabajo([]);
        setFilteredPlanTrabajos([]);
        return;
      }

      setPlanTrabajo(response);
      setFilteredPlanTrabajos(response.filter((materia) => materia.cicloEscolar === selectedCiclo));
    } catch (error) {
      console.error("Error fetching docentes:", error);
      setPlanTrabajo([]);
      setFilteredPlanTrabajos([]);
    } finally {
      setIsLoading(false);
    }
  }, [selectedCiclo, currentPage, setPlanTrabajo, setFilteredPlanTrabajos, userInfo]);

  const fetchReportPlanTrabajo = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getReportPT(currentPage, selectedCiclo, userInfo);
      if (!response) {
        console.warn("No report found.");
        return;
      }
      setFilteredPlanTrabajos(response);
      return response;
    } catch (error) {
      console.error("Error fetching report:", error);
      return;
    } finally {
      setIsLoading(false);
    }
  }, [selectedCiclo, currentPage, setFilteredPlanTrabajos, userInfo]);

  const getDetallePlanTrabajo = useCallback(async (id, codigo) => {
    try {
      setIsLoading(true);
      const response = await getDetallePT(id, codigo);
      if (!response) {
        console.warn("No detalle found.");
        return;
      }
      return response;
    } catch (error) {
      console.error("Error fetching detalle:", error);
      return;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { fetchDocentes, fetchTotalSize, fetchCicloActual, fetchReportPlanTrabajo, getDetallePlanTrabajo, isLoading };
};

export default useListCargaHoraria;
