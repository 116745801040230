import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEvaluationStore } from '../../../store/useEvaluationStore';
import arbolCriteriosJson from '../services/arbolCriterios.json';
import { datosEvaluacion, } from '../services/evaluacionService';
import ProcesarCriterios from './ProcesarCriterios';
import styles from './styles/FormCriterios.module.css';
import TablaResultadosEvaluacion from './TablaResultadosEvaluacion';

const FormCriterios = () => {
  const { selectedDataAcademico } = useEvaluationStore();
  const { control, handleSubmit, formState: { errors, isSubmitted } } = useForm();

  const [resultadosEvaluacion, setResultadosEvaluacion] = useState();
  const [arbolCriterios, setArbolCriterios] = useState([]);
  const [miembros, setMiembros] = useState([]);
  const [puntaje, setPuntaje] = useState({})

  useEffect(() => {
    datosEvaluacion(selectedDataAcademico?.id)
      .then(data => {
        setMiembros(data.miembros);
        setArbolCriterios(data.arbolCriterios);
        setResultadosEvaluacion(data.tablaResultados);
        setPuntaje(data.puntaje)
      })
      .catch(error => console.log(error))
    // eslint-disable-next-line
  }, [])

  /*   useEffect(() => {
      setArbolCriterios(arbolCriteriosJson.arbolCriterios)
    }, [])
  
    useEffect(() => {
      setMiembros(arbolCriteriosJson.miembros)
    }, []) */

  //console.log(arbolCriterios)

  const onSubmit = (data) => {
    console.log(miembros);
    console.log(data);
  };

  const [formData, setFormData] = useState({});

  if (arbolCriterios.length < 0) {
    return <p>Cargando</p>;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles.formEvaluacion}>

        {/* {renderCriterios(arbolCriterios)} */}
        <ProcesarCriterios criterios={arbolCriterios} puntaje={puntaje} />

      </div>

      {/* Sección del resto del formulario */}
      <div className={styles.restForm}>
        <div className={styles.containerInputsSelectRadio}>
          <div className={styles.inputSelect}>
            <select name="miembro" id="miembro">
              <option value="" disabled selected={true}>-- Seleccione su nombre de la lista --</option>
              {miembros.map(miembro => (
                <option key={miembro.codigo} value={miembro.codigo}>{miembro.nombre}</option>
              ))}
            </select>
          </div>
          {/* <div className={styles.inputRadio}>
            <div>
              <input type='radio' id='ratifica' />
              <label htmlFor='ratifica'>Ratifica</label>
            </div>
            <div>
              <input type='radio' id='modifica' />
              <label htmlFor='modifica'>Modifica</label>
            </div>
          </div> */}
        </div>
        {/* 
        <div className={styles.textarea}>
          <label htmlFor='respuestaAcademico' style={{ marginBottom: 20 }}>Escriba la respuesta del académico</label>
          <textarea name="respuestaAcademico" id="respuestaAcademico"></textarea>
        </div> */}

        <div className={styles.submit}>
          <input type='submit' value='Evaluar' />
          <TablaResultadosEvaluacion
            resultados={resultadosEvaluacion}
            nivel={arbolCriteriosJson.nivelPrimerEvaluacion}

          />
          {/* {isSubmitted && (
          )} */}
        </div>
      </div>
    </form>
  );
}

export default FormCriterios
