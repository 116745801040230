import React from 'react';
import styles from './styles/Alert.module.css';

/**
 * Componente Alert para mostrar mensajes de alerta.
 *
 * @param {Object} props - Props del componente.
 * @param {React.ReactNode} props.children - El contenido de la alerta, debe ser un elemento p html.
 * @param {'success' | 'error' | 'warning'} props.typeAlert - Tipo de alerta que define el estilo.
 *
 * @returns {JSX.Element} El componente Alert.
 */
const Alert = ({ children, typeAlert }) => {
    const alertClass = styles[`alert-${typeAlert}`] || styles.alert; // Fallback a un estilo por defecto
    return (
        <div className={`${alertClass} ${styles.space} ${styles.alert}`}>
            {children}
        </div>
    )
}

export default Alert
