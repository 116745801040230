import React, { useEffect, useState } from "react";
import styles from "../styles/ModalValidacionPlanTrabajo.module.css";

const ModalValidacionPlanTrabajo = ({ isOpen, onClose, data, onSubmit }) => {
  const [observaciones, setObservaciones] = useState(data.observaciones || "");
  const [estatus, setEstatus] = useState(data.estatus || "Aprobado");
  useEffect(() => {
    if (data) {
      setObservaciones(data.observaciones || "");
      setEstatus(data.estatus || "Aprobado");
    }
  }, [data]);

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      ...data,
      estatus,
      observaciones,
    };
    onSubmit(payload);
    onClose();
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <header className={styles.modalHeader}>
          <h3>Validación de Plan de Trabajo</h3>
        </header>
        <div className={styles.modalBody}>
          <div className={styles.solicitudContainer}>
            <form>
              <div className={styles.headerContainer}>
                <div className={styles.userInfoContainer}>
                  <div className={styles.column}>
                    <label className={styles.titleField}>Código de profesor</label>
                    <input
                      className={styles.displayField}
                      readOnly
                      value={data.codigoProfesor || "No disponible"}
                    />
                  </div>
                  <div className={styles.column}>
                    <label className={styles.titleField}>Nombre de docente</label>
                    <input
                      className={styles.displayField}
                      readOnly
                      value={data.nombreProfesor || "No disponible"}
                    />                  </div>

                  <div className={styles.column}>
                    <label className={styles.titleField}>No. Solicitud</label>
                    <input
                      className={styles.displayField}
                      readOnly
                      value={data.noSolicitud || "No disponible"}
                    />                  </div>

                  <div className={styles.column}>
                    <label className={styles.titleField}>Ciclo Escolar</label>
                    <input
                      className={styles.displayField}
                      readOnly
                      value={data.cicloEscolar || "No disponible"}
                    />                  </div>
                </div>
              </div>

              <div className={styles.formFields}>
                <div className={styles.formColumn}>
                  <div className={styles.titleField}>
                    <span className={styles.labelTitle}>Docencia</span>
                    <br />
                    (materias, tutorías, intervenciones, prácticas, etc.)
                  </div>
                  <textarea
                    className={styles.inputField}
                    readOnly
                    defaultValue={data.docencia || ""}
                  />
                </div>

                <div className={styles.formColumn}>
                  <div className={styles.titleField}>
                    <span className={styles.labelTitle}>
                      Generación y/o aplicación de conocimiento
                    </span>
                    <br />
                    (investigación aplicada, asimilación, desarrollo y
                    transferencia de tecnología)
                  </div>
                  <textarea
                    className={styles.inputField}
                    readOnly
                    defaultValue={data.generacionConocimiento || ""}
                  />
                </div>

                <div className={styles.formColumn}>
                  <div className={styles.titleField}>
                    <span className={styles.labelTitle}>
                      Gestión Académica Individual o Colegiada
                    </span>
                    <br />
                    (Participación en modelos educativos, comités, y actividades
                    académico-administrativas)
                  </div>
                  <textarea
                    className={styles.inputField}
                    readOnly
                    defaultValue={data.gestionAcademica || ""}
                  />
                </div>

                <div className={styles.formColumn}>
                  <div className={styles.titleField}>
                    <span className={styles.labelTitle}>
                      Vinculación, Difusión y Extensión de la Ciencia y la
                      Cultura
                    </span>
                    <br />
                    (Proyectos comunitarios, difusión cultural y preservación de
                    la identidad)
                  </div>
                  <textarea
                    className={styles.inputField}
                    readOnly
                    defaultValue={data.vinculacion || ""}
                  />
                </div>
              </div>

              <div className={styles.section}>
                <label>Estado</label>
                <select
                  value={estatus}
                  onChange={(e) => setEstatus(e.target.value)}
                  className={styles.select}
                >
                  <option value="Aprobado">Aprobado</option>
                  <option value="Rechazado">Rechazado</option>
                </select>
              </div>

              <div className={styles.section}>
                <label>Observaciones</label>
                <textarea
                  value={observaciones}
                  onChange={(e) => setObservaciones(e.target.value)}
                  placeholder="Escribe tus observaciones aquí..."
                  className={styles.textarea}
                />
              </div>

              <div className={styles.submit}>
                <button
                  className={styles.cancelButton}
                  type="button"
                  onClick={onClose}
                >
                  Cancelar
                </button>
                <button
                  className={styles.submitButton}
                  type="submit"
                  onClick={handleSubmit}
                >
                  Enviar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalValidacionPlanTrabajo;
