import { useCallback, useState } from "react";
import { obtenerMaterias, obtenerSizeList } from "../features/materiasService";

const useMaterias = (setMaterias, selectedCiclo, currentPage = 1, userInfo, setFilteredMaterias) => {
  const [isLoading, setIsLoading] = useState(false);

  const fetchMaterias = useCallback(async () => {
    try {
      setIsLoading(true);
      const codigoEmpleado = userInfo;
      const app = "PruebasSpertoDigital";

      const response = await obtenerMaterias(codigoEmpleado, selectedCiclo, currentPage, app);

      setMaterias(response);
      setFilteredMaterias(response.filter((materia) => materia.cicloEscolar === selectedCiclo));
    } catch (error) {
      console.error("Error fetching materias:", error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedCiclo, currentPage, setMaterias, setFilteredMaterias, userInfo]);

  return { fetchMaterias ,isLoading };
};

export default useMaterias;
