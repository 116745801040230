import React from "react";
import styles from "../styles/MenuPrincipal.module.css";
import MenuPrincipal from "../components/MenuPrincipal";

function CumplimientoTrabajoMain() {
  return (
    <>
      <article>
        <h3 className={styles.constancia_title}>
          Cumplimiento Plan de Trabajo
        </h3>
      </article>
      <MenuPrincipal />
    </>
  );
}

export default CumplimientoTrabajoMain;
