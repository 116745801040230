import React, { useState, useEffect } from "react";
import styles from "./styles/ReporteDictamenFinal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight, faSave } from "@fortawesome/free-solid-svg-icons";
import Search from "../../../reutilizable/Search";
import Select from "../../../reutilizable/Select";
import Table from "../../../reutilizable/Table";
import { useForm } from "react-hook-form";
import { useSearchStore } from "../../../store/useSearchStore";
import axios from "axios";

const ReporteDictamenFinal = () => {
  const { setValue, watch } = useForm();
  const { academicos, setAcademicos, clearSearch } = useSearchStore();
  const [filteredParticipants, setFilteredParticipants] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState(new Set());

  const CABECERAS_TABLA = [
    { id: 1, labelCabecera: "input" },
    { id: 2, labelCabecera: "#" },
    { id: 3, labelCabecera: "Apellido(S) Nombre(s)" },
    { id: 4, labelCabecera: "Tipo" },
    { id: 5, labelCabecera: "Dependencia" },
    { id: 6, labelCabecera: "Nivel" },
    { id: 7, labelCabecera: "Calidad" },
    { id: 8, labelCabecera: "Pts." },
  ];

  useEffect(() => {
    setFilteredParticipants(academicos);
  }, [academicos]);

  const handleSearch = (query) => {
    const filtered = academicos.filter((participant) =>
      Object.values(participant).some((value) => value?.toString().toLowerCase().includes(query.toLowerCase()))
    );
    setFilteredParticipants(filtered);
  };

  const handleClearFilters = () => {
    setFilteredParticipants(academicos);
    clearSearch();
    setSelectedParticipants(new Set());
  };

  const handleCheckboxChange = (id) => {
    const updatedSelection = new Set(selectedParticipants);
    if (updatedSelection.has(id)) {
      updatedSelection.delete(id);
    } else {
      updatedSelection.add(id);
    }
    setSelectedParticipants(updatedSelection);
  };

  const downloadPDF = (pdfBytes) => {
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "reporte.pdf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleGeneratePDF = async () => {
    if (selectedParticipants.size === 0) {
      alert("Seleccione al menos un académico para generar el PDF.");
      return;
    }

    const selectedCodes = Array.from(selectedParticipants);
    const payload = { academicosSeleccionados: selectedCodes };

    try {
      const token = localStorage.getItem("accessToken");
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/dictamen/dictamen-final`,
        payload,
        {
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          responseType: "arraybuffer",
        }
      );
      if (response.status === 200) {
        downloadPDF(response.data);
      }
    } catch (error) {
      console.error("Error al generar el PDF:", error);
      alert("Ocurrió un error al generar el PDF. Por favor, intente nuevamente.");
    }
  };

  return (
    <div className={styles.container}>
      <p className={styles.title_page}>Dictamen final</p>

      <div className={styles.container_parrafo}>
        <p className={styles.p_instrucciones}>
          <FontAwesomeIcon icon={faAngleRight} color="yellow" /> Instrucciones
        </p>
        <p className={styles.parrafo_instrucciones}>
          Seleccione de la tabla los académicos para los cuales se vaya a generar el reporte. Elija los que desee usando
          las casillas <br /> correspondientes, o todos los de la tabla haciendo clic en la casilla de la cabecera.{" "}
          <br />
          También puede realizar búsquedas específicas por nombre, apellido, código, tipo de participación u otros datos
          del académico.
        </p>
      </div>

      <div className={styles.container_table}>
        <div className={styles.container_filtros_tabla}>
          <div>
            <p>Buscar:</p>
            <Search placeholder="Teclee su búsqueda" onSearch={handleSearch} />
          </div>
          <p>en</p>
          <div>
            <Select
              form={{ setValue, watch }}
              name="dependenciaSeleccionada"
              placeholder="Seleccione una dependencia"
              onSelectParticipants={setAcademicos}
            />
          </div>
        </div>

        <Table cabecerasTable={CABECERAS_TABLA} bodyTable={filteredParticipants} withHeader={true}>
          {filteredParticipants.length > 0 ? (
            filteredParticipants.map((dataBody) => (
              <tr key={dataBody.id}>
                <td className={`${styles.td_checkbox}`}>
                  <input
                    type="checkbox"
                    checked={selectedParticipants.has(dataBody.id)}
                    onChange={() => handleCheckboxChange(dataBody.id)}
                  />
                </td>
                <td className={`${styles.td} ${styles.td_textCenter}`}>{dataBody.id}</td>
                <td className={`${styles.td} ${styles.td_nombre}`}>
                  {dataBody.nombre} {dataBody.apellidoPaterno} {dataBody.apellidoMaterno}
                </td>
                <td className={`${styles.td} ${styles.td_textCenter}`}>{dataBody.nombreRol}</td>
                <td className={`${styles.td} ${styles.td_textCenter}`}>{dataBody.siglasDependencia}</td>
                <td className={`${styles.td} ${styles.td_textCenter}`}>{dataBody.nivelRomano}</td>
                <td className={`${styles.td} ${styles.td_textCenter}`}>{dataBody.calidad}</td>
                <td className={`${styles.td} ${styles.td_textCenter}`}>{dataBody.puntuacion}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={CABECERAS_TABLA.length} className={styles.noData}>
                No hay datos disponibles.
              </td>
            </tr>
          )}
        </Table>
      </div>

      <div className={styles.container_buttons}>
        <button type="button" className="texto_con_icono" onClick={handleGeneratePDF}>
          Generar PDF <FontAwesomeIcon icon={faSave} color="cyan" />
        </button>
      </div>
    </div>
  );
};

export default ReporteDictamenFinal;
