import React, { useState, useEffect, useCallback } from "react";
import FiltroSubirDocumentos from "../components/FiltroSubirDocumentos";
import SubirDocumentosTableDetalles from "./SubirDocumentosTableDetalles";
import Paginacion from "../../../../../reutilizable/Paginacion";
import styles from "../styles/SubirDocumentosTable.module.css";

const SubirDocumentosTable = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDependencia, setSelectedDependencia] = useState("");
  const [filteredMaterias, setFilteredMaterias] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const mockDocentes = React.useMemo(
    () => [
      { id: 1, codigoProfesor: "A001", nombreProfesor: "Juan Pérez" },
      { id: 2, codigoProfesor: "A002", nombreProfesor: "María Gómez" },
      { id: 3, codigoProfesor: "A003", nombreProfesor: "Carlos Ruiz" },
      { id: 4, codigoProfesor: "A004", nombreProfesor: "Laura Jiménez" },
      { id: 5, codigoProfesor: "A005", nombreProfesor: "José Martínez" },
      { id: 6, codigoProfesor: "A006", nombreProfesor: "Ana López" },
      { id: 7, codigoProfesor: "A007", nombreProfesor: "Luis Sánchez" },
      { id: 8, codigoProfesor: "A008", nombreProfesor: "Marta Torres" },
      { id: 9, codigoProfesor: "A009", nombreProfesor: "Diego Castro" },
      { id: 10, codigoProfesor: "A010", nombreProfesor: "Elena Vargas" },
      { id: 11, codigoProfesor: "A011", nombreProfesor: "Rafael Romero" },
      { id: 12, codigoProfesor: "A012", nombreProfesor: "Gloria Hernández" },
    ],
    []
  );

  const fetchDocentes = useCallback(() => {
    setIsLoading(true);
    try {
      let filtered = mockDocentes.filter(
        (docente) =>
          (!searchTerm ||
            docente.nombreProfesor.toLowerCase().includes(searchTerm.toLowerCase()) ||
            docente.codigoProfesor.toLowerCase().includes(searchTerm.toLowerCase())) &&
          (!selectedDependencia || docente.codigoProfesor.includes(selectedDependencia))
      );

      if (sortConfig.key) {
        filtered = filtered.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === "asc" ? 1 : -1;
          }
          return 0;
        });
      }

      setTotalItems(filtered.length);

      const paginated = filtered.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
      setFilteredMaterias(paginated);
    } catch (error) {
      console.error("Error fetching docentes: ", error);
    } finally {
      setIsLoading(false);
    }
  }, [searchTerm, selectedDependencia, currentPage, itemsPerPage, mockDocentes, sortConfig]);

  useEffect(() => {
    fetchDocentes();
  }, [fetchDocentes]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleDependenciaChange = (e) => {
    setSelectedDependencia(e.target.value);
    setCurrentPage(1);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? "▲" : "▼";
    }
    return null;
  };

  const handleFirstPage = () => setCurrentPage(1);
  const handleLastPage = () => setCurrentPage(Math.ceil(totalItems / itemsPerPage));
  const handlePreviousPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(totalItems / itemsPerPage)));

  return (
    <div className={styles.container}>
      {isLoading ? (
        <p>Cargando datos...</p>
      ) : (
        <>
          <div className={`${styles.filterRow}`}>
            <FiltroSubirDocumentos
              searchTerm={searchTerm}
              handleSearchChange={handleSearchChange}
              selectedDependencia={selectedDependencia}
              handleDependenciaChange={handleDependenciaChange}
              cicloDisponible
            />
          </div>

          <SubirDocumentosTableDetalles
            currentItems={filteredMaterias}
            handleSort={handleSort}
            getSortIcon={getSortIcon}
          />

          <Paginacion
            currentPage={currentPage}
            totalPages={Math.ceil(totalItems / itemsPerPage)}
            onFirstPage={handleFirstPage}
            onLastPage={handleLastPage}
            onPreviousPage={handlePreviousPage}
            onNextPage={handleNextPage}
          />
        </>
      )}
    </div>
  );
};

export default SubirDocumentosTable;
