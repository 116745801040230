import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import Table from '../../../../reutilizable/Table';
import Modal from '../../../../reutilizable/Modal';
import ViewerPDF from '../../../../reutilizable/ViewerPDF';
import CargarEvidencia from './CargarEvidencia';
import EliminarEvidencia from './EliminarEvidencia';
import { obtenerItemsDeCriteriosExpediente } from '../services/criteriosExpedienteService';
import { replaceBrWithNewline } from '../../../../utils/index';
import styles from './styles/TablaItemsEvaluacion.module.css';

/** 
 * Componente que retorna la tabla con la lista de criterios del expediente
 * @param {Object} params - Props objeto de parametros que necesita para obtener el listado de criterios
 * @param {number} params.idSolicitud - Identificador de la solicitud.
 * @param {number} params.idMenu - Identificador del menú.
 * @param {number} params.idSubMenu - Identificador del submenú.
 */
const TablaItemsEvaluacion = ({ params }) => {

  const [uploadedDocs, setUploadedDocs] = useState(new Set());
  const [itemsEvaluacion, setItemsEvaluacion] = useState([]);
  const [itemsCriterios, setItemsCriterios] = useState([]);
  const [urlPdf, setUrlPdf] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cabecerasTable, _] = useState([
    {
      id: 1,
      labelCabecera: 'Item'
    },
    {
      id: 2,
      labelCabecera: 'Descripcion Actividad'
    },
    {
      id: 3,
      labelCabecera: 'Documento Probatorio (evidencia)'
    },
    {
      id: 4,
      labelCabecera: 'Puntos'
    },
    {
      id: 5,
      labelCabecera: 'Cargar Evidencia'
    },
    {
      id: 6,
      labelCabecera: 'Eliminar'
    }
  ]);

  useEffect(() => {
    if (!params) return;

    setItemsCriterios([]);
    obtenerItemsDeCriteriosExpediente(params)
      .then(response => {
        //setItemsCriterios(response);
        setItemsCriterios(response?.data)
        //console.log(response.data.arbol)
      })
      .catch(error => console.error('Error al obtener los items del criterio para expediente ', error))
  }, [params, uploadedDocs])

  useEffect(() => {
    if (!itemsCriterios || !itemsCriterios.arbol) return;
    console.log(itemsCriterios?.arbol)
    setItemsEvaluacion(itemsCriterios?.arbol);
  }, [itemsCriterios]);

  const handleUploadSuccess = (idExpediente) => {
    setUploadedDocs((prev) => {
      const newSet = new Set(prev);
      newSet.add(idExpediente);
      return newSet;
    });
  };

  const handleDeleteSuccess = (idExpediente) => {
    setUploadedDocs((prev) => {
      const newSet = new Set(prev);
      newSet.delete(idExpediente);
      return newSet;
    });
  };

  const openModal = () => setIsModalOpen(!isModalOpen);
  const closeModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ViewerPDF urlPdf={urlPdf} />
      </Modal>

      <Table cabecerasTable={cabecerasTable}>
        {itemsEvaluacion?.map(itemEvaluacion => {
          // Desestructuracion del objeto
          const { nodo } = itemEvaluacion
          const { id, nombre, documentos, tope } = itemEvaluacion.criterio;

          // Validaciones para renderizado condicional
          const existeDocumento = nodo !== null || uploadedDocs.has(id);
          const sinNombreDocumento = documentos === "";
          const sinPuntaje = tope === null;
          
          return (
            <tr key={id}>
              <td>{id}</td>
              <td>{replaceBrWithNewline(nombre)}</td>
              <td>
                {sinNombreDocumento ? (
                  <p>Sin registro de documento probatorio</p>
                ) : (
                  replaceBrWithNewline(documentos)
                )}
              </td>
              <td>
                {sinPuntaje ? (
                  <p>Sin puntaje registrado para este ítem</p>
                ) : (
                  <p>{tope}</p>
                )}
              </td>
              <td className={styles.tdEvidencia}>
                {existeDocumento ? (
                  <div className={styles.containerBtnPDF}>
                    <button
                      type='button'
                      className={styles.buttonPdf}
                      title='Ver PDF'
                      onClick={() => {
                        openModal();
                        setUrlPdf(nodo);
                      }}
                    >
                      <FontAwesomeIcon icon={faFilePdf} color='red' />
                    </button>
                  </div>
                ) : (
                  <CargarEvidencia
                    tipoUpload='criterio'
                    idExpediente={Number(id)}
                    idSolicitud={Number(params.idSolicitud)}
                    onUploadSuccess={() => handleUploadSuccess(id)}
                  />
                )}
              </td>
              <td>
                <EliminarEvidencia
                  tipoDelete='criterio'
                  idExpediente={id}
                  idSolicitud={Number(params.idSolicitud)}
                  activo={existeDocumento}
                  onDeleteSuccess={() => handleDeleteSuccess(id)}
                />
              </td>
            </tr>
          );
        })
        }
      </Table>
    </>
  );
};

export default TablaItemsEvaluacion;
