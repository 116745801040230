import React from "react";
import styles from "../../styles/InfoLeft.module.css";
import { getInfoAcademico } from "../../services/getInfoAcademico";

const InfoLeft = ({ handleCOdeAcademico, codeAcademico, addInfoAcademico }) => {
  const onGetInfoAcademico = async () => {
    try {
      const res = await getInfoAcademico(codeAcademico);
      addInfoAcademico(true);
    } catch (error) {
      addInfoAcademico(true);
    }
  };

  return (
    <div className={styles.leftInfo}>
      <h3 className={styles.codeTitle}>Código:</h3>
      <input
        type="text"
        className={styles.inputCode}
        onChange={handleCOdeAcademico}
        value={codeAcademico}
      />
      <button className={styles.btnCode} onClick={() => onGetInfoAcademico()}>
        Continuar
      </button>
    </div>
  );
};

InfoLeft.propTypes = {};

export default InfoLeft;
