import React, {  useState } from "react";
import styles from "../styles/SubirDocumentosTableDetalles.module.css";
import useGenerateOficios from "../hooks/useGenerateOficios";

const API_URL_DOCUMENT_TYPES = `${process.env.REACT_APP_API_BASE_URL}/api/v1/document/types`;

const SubirDocumentosTableDetalles = ({
    currentItems,
    cicloDisponible,
    getSortIcon,
    handleSort,
}) => {
    const { fetchAltaOficio, fetchActualizarOficio, fetchEliminarOficio, isLoading } = useGenerateOficios();
    const [documentTypes, setDocumentTypes] = useState([
        { id: 1, nombre: "Solicitud" },
        { id: 2, nombre: "Tabla" },
        { id: 3, nombre: "Dictamen" },
        { id: 4, nombre: "Tabla INC" },
        { id: 5, nombre: "Dictamen INC" },
    ]);
    const [selectedDocumentType, setSelectedDocumentType] = useState(
        currentItems.map(() => "")
    );

    const handleDocumentTypeChange = (index, value) => {
        const updatedDocumentTypes = [...selectedDocumentType];
        updatedDocumentTypes[index] = value;
        setSelectedDocumentType(updatedDocumentTypes);
    };

    const handleGenerarOActualizarDocumento = async (materia, index) => {
        if (!selectedDocumentType[index]) {
            alert("Por favor, seleccione un tipo de documento antes de continuar.");
            return;
        }

        const payload = {
            codigoProfesor: materia.codigoProfesor,
            tipoDocumento: selectedDocumentType[index],
        };

        try {
            if (materia.marcado === "0") {
                await fetchAltaOficio(payload);
                alert("Documento generado exitosamente.");
            } else {
                await fetchActualizarOficio(payload);
                alert("Documento actualizado exitosamente.");
            }
        } catch (error) {
            alert("Hubo un problema al procesar la solicitud.");
            console.error(error);
        }
    };

    const handleEliminarDocumento = async (materia, index) => {
        if (!selectedDocumentType[index]) {
            alert("Por favor, seleccione un tipo de documento antes de eliminar.");
            return;
        }

        if (!window.confirm("¿Estás seguro de que deseas eliminar este documento? Esta acción no se puede deshacer.")) {
            return;
        }

        try {
            const payload = {
                codigoProfesor: materia.codigoProfesor,
                tipoDocumento: selectedDocumentType[index],
            };
            await fetchEliminarOficio(payload);
            alert("Documento eliminado correctamente.");
        } catch (error) {
            console.error("Error al eliminar el documento:", error);
            alert("Hubo un problema al eliminar el documento. Por favor, inténtalo de nuevo.");
        }
    };

    return (
        <>
            <table className={styles.table}>
                <thead>
                    <tr>
                        <th onClick={() => handleSort("codigoProfesor")}>CÓDIGO PROFESOR {getSortIcon("codigoProfesor")}</th>
                        <th onClick={() => handleSort("nombreProfesor")}>NOMBRE DOCENTE {getSortIcon("nombreProfesor")}</th>
                        <th>TIPO DE DOCUMENTO</th>
                        <th>CARGAR/ACTUALIZAR</th>
                        <th>ELIMINAR</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.length > 0 ? (
                        currentItems.map((materia, index) => (
                            <tr key={index}>
                                <td>{materia.codigoProfesor}</td>
                                <td>{materia.nombreProfesor}</td>
                                <td>
                                    <select
                                        value={selectedDocumentType[index]}
                                        onChange={(e) => handleDocumentTypeChange(index, e.target.value)}
                                    >
                                        <option value="">Seleccione un tipo de documento</option>
                                        {documentTypes.map((docType) => (
                                            <option key={docType.id} value={docType.id}>
                                                {docType.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                                <td>
                                    <button
                                        className={styles.button}
                                        onClick={() => handleGenerarOActualizarDocumento(materia, index)}
                                        disabled={isLoading}
                                    >
                                        {materia.marcado === "0" ? "Subir" : "Actualizar"}
                                    </button>
                                </td>
                                <td>
                                    <button
                                        className={styles.button}
                                        onClick={() => handleEliminarDocumento(materia, index)}
                                        disabled={isLoading || !cicloDisponible}
                                    >
                                        Eliminar
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5">No hay docentes disponibles</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </>
    );
};

export default SubirDocumentosTableDetalles;
