import React from "react";
import styles from "../styles/ModalPDF.module.css"; 

const ModalPDF = ({ isOpen, message, onClose, showSaveButton, onSave  }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modalContent}>
        <p>{message}</p>
        <div className={styles.modalActions}>
          <button onClick={onClose} className={styles.closeButton}>
            Close
          </button>
          {showSaveButton && (
            <button onClick={onSave} className={styles.saveButton}>
              Save
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalPDF;
