import { useState, useEffect } from "react";
import { buscarSolicitud, toggleSolicitud } from "../../solicitudService";
import styles from "./styles/SuperPase.module.css";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const API_URL_SUPERPASE = `${API_BASE_URL}/api/v1/superpase/`;

export const SuperPase = () => {
    const [codigo, setCodigo] = useState("");
    const [error, setError] = useState("");
    const [academico, setAcademico] = useState({})
    const [superPase, setSuperPase] = useState(false)

    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => { setError(""); }, 3000);
            return () => clearTimeout(timer);
        }
    }, [error]);

    const handleSubmitCodigo = (event) => {
        event.preventDefault();

        if (!codigo.trim()) {
            setError("Campo obligatorio");
            return;
        }

        const token = localStorage.getItem('accessToken');

        axios.get(`${API_URL_SUPERPASE}${codigo}`, {
            headers: {
                Accept: '*/*',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }).then(response => {
            console.log("response ", response.data)
            setAcademico(response.data)
            setCodigo(codigo)
            setSuperPase(response.data.superpase)
        })
            .catch(error => setError("No se encontro el código"))
    }


    const handleFormSubmit = (event) => {
        event.preventDefault();
        const token = localStorage.getItem('accessToken');


        axios.put(`${API_URL_SUPERPASE}${codigo}`, { activar: superPase }, {
            headers: {
                Accept: '*/*',
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },

        }
        ).then(response => {
            console.log("then ", response)
        })
            .catch(error => {
                console.error("Error fetching data: ", error.response?.data || error.message);
            });

    };

    return (<>
        <div className={styles.mainContainer}>
            <div className={styles.containerAside}>
                <div className={styles.formContainer}>
                    <span className={styles.codigo}>Código:</span>
                    <div style={{ width: "100%" }}> {/* Contenedor para asegurar alineación */}
                        <input
                            type="text"
                            name="codigo"
                            id="codigo"
                            className={styles.inputField}
                            onChange={(e) => setCodigo(e.target.value)}
                        />
                    </div>
                    <div className={styles.buttonContainer}>
                        <button
                            className={styles.button}
                            name="busca_solicitud"
                            id="busca_solicitud"
                            value="Buscar"
                            type="button"
                            onClick={handleSubmitCodigo}
                        >
                            Continuar
                        </button>
                    </div>
                </div>

            </div>
            {/* Form */}

            <div className={styles.form}>

                {Object.keys(academico).length !== 0 ? (
                    <>
                        <h3>
                            <strong>
                                Cambie el valor del "superpase" y después asegurese de oprimir el botón "Guardar"
                            </strong>
                        </h3>
                        <form className={styles.container} onSubmit={handleFormSubmit}>
                            <div>
                                <span className={styles.subTiles}>Datos personales</span>
                                <div>
                                    <p className={styles.dataParticipante}>{academico?.nombre} {academico?.apellidoPaterno} {academico?.apellidoMaterno} {academico?.codigo}</p>
                                </div>
                            </div>

                            <div>
                                <span className={styles.subTiles}>Super pase</span>
                                <div className={styles.fechaInput}>
                                    <label htmlFor="superPaseSi">Tiene super pase: </label>
                                    <input
                                        type="radio"
                                        name="superPase"
                                        id="superPaseSi"
                                        value="true"
                                        checked={superPase === true}
                                        onChange={() => setSuperPase(true)}
                                    />
                                    <label htmlFor="superPaseSi">Sí</label>

                                    <input
                                        type="radio"
                                        name="superPase"
                                        id="superPaseNo"
                                        value="false"
                                        checked={superPase === false}
                                        onChange={() => setSuperPase(false)}
                                    />
                                    <label htmlFor="superPaseNo">No</label>
                                </div>
                            </div>

                            <div className={styles.buttonForm}>
                                <button type="submit" className={styles.button}>Guardar</button>
                            </div>

                        </form>
                    </>
                ) : (<h3>
                    <strong>
                        Introduzca el código del académico y oprima el botón "Continuar".
                    </strong>
                </h3>)}


                <br></br>
                <br></br>
                {error && (
                    <p className={styles.error}>{error}</p>
                )
                }
            </div>


        </div>
    </>
    );
};