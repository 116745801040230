import React, { useCallback, useEffect, useState } from "react";
import TablaMateriasEstudiante from "../components/TablaMateriasEstudiante";
import styles from "../css/CargaEstudiantePage.module.css";
import { obtenerSizeList } from "../../../materiasService";

function EvaluacionEstudiantePage() {
  const [loading, setIsLoading] = useState();
  const [sizeListProfesor, setSizeListProfesor] = useState(0);

  const fetchTotalSize = useCallback(async () => {
    try {
      setIsLoading(true);
      const size = await obtenerSizeList();
      setSizeListProfesor(size);
    } catch (error) {
      console.error("Error fetching total size:", error);
      setSizeListProfesor(0);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTotalSize();
  }, [fetchTotalSize]);

  const totalProfesor = sizeListProfesor;
  return (
    <>
      <article>
        <header>
          <h3 className={styles.constancia_title}>Evaluacion Estudiante</h3>
        </header>

        <TablaMateriasEstudiante totalProfesor={totalProfesor} />
      </article>
    </>
  );
}

export default EvaluacionEstudiantePage;
