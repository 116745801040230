import React, { useEffect, useState } from "react";
import styles from "../styles/TablaCumplimientoTrabajo.module.css";
import useCumplimientoTrabajoContext from "../context/useCumplimientoTrabajoContext";
import useGenerateOficios from "../../components/carga_horaria/hooks/useGenerateOficios";
import useMunicipios from "../../../../hooks/useMunicipios";

function TablaCumplimientoTrabajo() {
  const {
    currentCiclo,
    getProfesoresList,
    profesoresList,
    isProfesoresLoading,
  } = useCumplimientoTrabajoContext();
  useEffect(() => {
    // Construccion del request para lista profesores
    const requestBody = {
      codigoEmpleado: localStorage.getItem("userName"),
      cicloEscolar: currentCiclo,
      page: "1",
    };
    console.log(requestBody);
    // Consulta API
    const fetchProfesores = async () => {
      await getProfesoresList(requestBody);
    };

    fetchProfesores();

  }, [ currentCiclo, getProfesoresList, ]);

  const { fetchAltaOficio, fetchConsultaOficio, fetchActualizarOficio, fetchEliminarOficio, isLoading } = useGenerateOficios();
  const { municipios } = useMunicipios();

  /**
   * Inicio Refactorizacion
   * Son metodos que se llegaran a ocupar para la logica de negocio, sin embargo no estan implemetados pera era necesario declararlos para establecer la logica y mostrar la tabla.
   * 
   * Para que puedan ser refactorizado, movidos a sus hooks respectivos, trabajarlos como piensen mejor y se adapte al companero.
   */

  const [editMode, setEditMode] = useState(false);
  const [selectedMunicipio, setSelectedMunicipio] = useState("");
  const [noOficioValues, setNoOficiosValues] = useState(profesoresList.map((item) => item.noOficio || ""));

  const handleSort = (data) => {
    console.log(data);
  };

  const getSortIcon = (data) => {
    console.log(data);
  };

  const handleRowClick = (profesor) => {
    console.log(profesor);
  }

  const handleNoOficioChange = (index, value) => {
    console.log(index, value);
  }

  const getMunicipioName = (ubicacion) => {
    if (!ubicacion) return "No disponible";

    const municipio = municipios.find(
        (m) => m.id === Number(ubicacion) || m.municipio.toString() === ubicacion.toString()
    );
    return municipio ? municipio.municipio : "No disponible";
};

const handleDescargarPDF = async (idQr) => {
    try {
        const response = await fetchConsultaOficio(idQr);
    } catch (error) {
        console.error("Error al descargar el PDF:", error);
        alert("Hubo un problema al descargar el PDF.");
    }
};

const handleGenerarOActualizarPDF = async (profesorMateria, action) => {
    console.log(profesorMateria);
    console.log(action);
  };

  const handleSaveRow = (index) => {
    console.log(index);
  }

  const handleEliminarOficio = (idQrProfesor, index) => {
    console.log(idQrProfesor);
  }

  const toggleEditMode = (index) => {
    console.log(index);
  }


// Fin Refactorizar

  if (isProfesoresLoading) return <p>Cargando Lista de profesores...</p>;
  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            <th onClick={() => handleSort("codigoProfesor")}>
              CÓDIGO PROFESOR {getSortIcon("codigoProfesor")}
            </th>
            <th onClick={() => handleSort("nombreProfesor")}>
              NOMBRE DOCENTE {getSortIcon("nombreProfesor")}
            </th>
            <th>NO. OFICIO</th>
            <th>MUNICIPIO</th>
            <th>CICLO ESCOLAR</th>
            <th>GENERAR/ACTUALIZAR</th>
            <th>DESCARGAR</th>
            <th>EDITAR</th>
            <th>ELIMINAR</th>
          </tr>
        </thead>
        <tbody>
          {profesoresList.length > 0 ? (
            profesoresList.map((profesor, index) => (
              <tr
                key={index}
                onClick={(event) => handleRowClick(profesor, index, event)}
                className={editMode[index] ? styles.clickableRow : ""}
                style={{ cursor: editMode[index] ? "pointer" : "default" }}
                title={editMode[index] ? "Haz clic para abrir el modal" : ""}
              >
                <td>{profesor.codigoProfesor}</td>
                <td>{profesor.nombreProfesor}</td>
                <td>
                  {editMode[index] ? (
                    <input
                      type="text"
                      value={noOficioValues[index]}
                      onChange={(e) =>
                        handleNoOficioChange(index, e.target.value)
                      }
                    />
                  ) : (
                    noOficioValues[index]
                  )}
                </td>
                <td>
                  {getMunicipioName(profesor.ubicacion || selectedMunicipio)}
                </td>
                <td>{profesor.cicloEscolar}</td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => handleGenerarOActualizarPDF(profesor, index)}
                    disabled={isLoading || !noOficioValues[index]}
                  >
                    {profesor.marcado === "0"
                      ? "Generar PDF"
                      : "Actualizar PDF"}
                  </button>
                </td>
                <td>
                  {profesor.marcado === "0" ? null : (
                    <button
                      className={styles.button}
                      onClick={() => handleDescargarPDF(profesor.idQr)}
                      disabled={isLoading || !noOficioValues[index]}
                    >
                      Descargar PDF
                    </button>
                  )}
                </td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => {
                      if (editMode[index]) handleSaveRow(index);
                      else toggleEditMode(index);
                    }}
                    disabled={isLoading || !currentCiclo}
                  >
                    {editMode[index] ? "Guardar" : "Editar"}
                  </button>
                </td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => handleEliminarOficio(profesor.idQr, index)}
                    disabled={
                      isLoading || profesor.marcado === "0" || !currentCiclo
                    }
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="15">
                No hay docentes disponibles para el ciclo seleccionado
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
}

export default TablaCumplimientoTrabajo;
