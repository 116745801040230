import { Checkbox } from "antd";
import styles from "./ConditionRow.module.css";

const breaches = [
  {
    id: 1,
    breach: "breach 1",
  },
  {
    id: 2,
    breach: "breach 2",
  },
  {
    id: 3,
    breach: "breach 3",
  },
];

const ConditionRow = ({ breach, handleAddBreachToDelete }) => {
  const selectBreachToDelete = () => {
    handleAddBreachToDelete(breach);
  };

  return (
    <tr className={styles.condicionesTableBodyTr}>
      <td>
        <div className={styles.btnDelete}>
          <Checkbox onClick={selectBreachToDelete} />
        </div>
      </td>
      <td className={styles.conditionRowSelect}>
        <select
          className={styles.conditionSelect}
          // defaultValue={0}
          value={0}
          onChange={() => {}}
        >
          <option className={styles.conditionOption} disabled value={0}>
            Selecciona un incumplimiento
          </option>
          {breaches.map((breach, index) => (
            <option key={index} value={breach.id}>
              incumplimiento {index}
            </option>
          ))}
        </select>
      </td>
    </tr>
  );
};

export default ConditionRow;
