import React from "react";
import { useInfoRight } from "../../hooks/useInfoRight";
import styles from "../../styles/InfoRight.module.css";
import ConditionRow from "../ConditionRow/ConditionRow";
import ModalActionIncumplimiento from "../ModalActionIncumplimiento/ModalActionIncumplimiento";

const InfoRight = () => {
  const {
    addBreach,
    breaches,
    cleanBreaches,
    modalOpen,
    onCloseModal,
    onDeleteBreach,
    onOpenModal,
    onSaveBreach,
    handleAddBreachToDelete,
  } = useInfoRight();

  return (
    <div className={styles.rightInfo}>
      <ModalActionIncumplimiento
        isOpen={modalOpen.state}
        onCLoseModal={onCloseModal}
        infoModal={modalOpen.action}
        actionBreach={
          modalOpen.action === "delete" ? onDeleteBreach : onSaveBreach
        }
      />
      <p className={styles.textAlert}>
        Elimine o agregue incumplimientos y después asegúrese de oprimir el
        botón de "Guardar".
      </p>
      <h3 className={styles.textlistdbet}>Lista de adeudos del Académico</h3>
      <p className={styles.textTotal}>
        Total de adeudos: <span>0</span>
      </p>
      <p className={styles.textInfoAcademic}>
        Leal moya irma leticia <span>2109379</span>
      </p>

      <div className={styles.tableContainer}>
        {/* Table */}
        <table className={styles.condicionesTable}>
          <thead className={styles.condicionesTableThead}>
            <tr>
              <th className={styles.condicionesTableTh}></th>
              <th className={styles.condicionesTableTh}>Condición</th>
            </tr>
          </thead>
          <tbody>
            {breaches.map((breach, index) => (
              <ConditionRow
                key={index}
                breach={breach}
                handleAddBreachToDelete={handleAddBreachToDelete}
                index={index}
              />
            ))}
          </tbody>
        </table>

        {/* actions */}
        <div className={styles.containerActions}>
          <button
            className={`${styles.btnAction} ${styles.btnActionPrimaryCancel}`}
            onClick={cleanBreaches}
          >
            Cancelar
          </button>
          <button
            className={`${styles.btnAction} ${styles.btnActionAdd}`}
            onClick={() => addBreach()}
          >
            Agregar
          </button>
          <button
            className={`${styles.btnAction} ${styles.btnActionPrimary}`}
            onClick={() => onOpenModal("save")}
          >
            Guardar
          </button>
          <button
            className={`${styles.btnAction} ${styles.btnActionDelete}`}
            onClick={() => onOpenModal("delete")}
          >
            Eliminar
          </button>
        </div>
      </div>
    </div>
  );
};

InfoRight.propTypes = {};

export default InfoRight;
