import React, { useState, useEffect, useCallback } from "react";
import styles from "../styles/TablaInconformidad.module.css";
import Modal from "./ModalPDF"
const TablaInconformidad = ({
  currentItems = [],
  currentPage,
  handleClickSave,
  handleClickCancel
  }) => {
  const [loading, setIsLoading] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [showSaveButton, setShowSaveButton] = useState(false);

  const handleConsultarPDF = (e) => {
    e.preventDefault();
    // Aquí llamar al servicio que genera el PDF
    // handleClickSave(materia);
    console.log("Ejemlo consulta pdf")
    
  }

    // Handle the "Ver PDF" button click
    const handleVerPDF = () => {
      setModalMessage("PDF PROBATORIO");
      setIsModalOpen(true);
      setShowSaveButton(false);
    };
  
    // Handle the "Generar PDF" button click
    const handleGenerarPDF = () => {
      setModalMessage("PDF CREADO");
      setIsModalOpen(true);
      setShowSaveButton(true);
    };

    const handleSave = () => {
      console.log("PDF has been saved!");
      setIsModalOpen(false);
      setShowSaveButton(false);
    };

  return (
    <div>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>CODIGO</th>
            <th>NOMBRE</th>
            <th>PROBATORIO</th>
            <th>GENERAR</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 ? (
            currentItems.map((materia, index) => {
              return (
                <tr key={index}>
                  <td>{materia.codigoProfesor}</td>
                  <td>{materia.nombreProfesor}</td>
                  <td>
                    {materia.probatorio !== "1" ? "null" : (
                      <button 
                      className={`${styles.button}`} 
                      onClick={handleVerPDF}

                      >
                        Ver PDF
                      </button>
                    )}
                  </td>
                  <td>
                    
                      <button
                        className={`${styles.button}`}
                        onClick={() => {
                          if (materia.marcado === "1") {
                            console.log("PDF DESCARGADO");
                          } else {
                            handleGenerarPDF();
                          }
                        }}
                      >
                        {materia.marcado === "1" ? "Descargar" : "Generar"}
                      </button>
                    
                  </td>
                 
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan="15">No hay docentes con los filtros aplicado</td>
            </tr>
          )}
        </tbody>
      </table>
      <Modal
        isOpen={isModalOpen}
        message={modalMessage}
        showSaveButton={showSaveButton}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSave}

      />
    </div>
  );
};

export default TablaInconformidad;
