import React from "react";
import styles from "../../styles/ModalActionIncumplimiento.module.css";

const ModalActionIncumplimiento = ({
  isOpen,
  onCLoseModal,
  infoModal,
  actionBreach,
}) => {
  if (!isOpen) return null;
  const textTitle = infoModal === "delete" ? "Eliminar" : "Procesar";

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <header className={styles.modalHeader}>
          <h3 className={styles.modalHeaderTitle}>{textTitle} registro</h3>
        </header>
        <div className={styles.modalBody}>
          <p className={styles.modalBodyText}>¿Deseas continuar?</p>

          <div className={styles.modalContainerActions}>
            <button
              className={`${styles.btnAction} ${styles.btnActionPrimaryCancel}`}
              onClick={onCLoseModal}
            >
              No
            </button>
            <button
              className={`${styles.btnAction} ${styles.btnActionPrimary}`}
              onClick={actionBreach}
            >
              Si
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalActionIncumplimiento;
