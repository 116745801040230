import React, { useEffect } from "react";
import styles from "../styles/TablaPlanTrabajoDetallado.module.css";
import useCumplimientoTrabajoContext from "../context/useCumplimientoTrabajoContext";
import TablaCumplimientoTrabajo from "./TablaCumplimientoTrabajo";
import FiltroCargaCumplimientoTrabajo from "./FiltroCargaCumplimientoTrabajo";

function MenuPrincipal() {
  const { currentCiclo, isCicloLoading, getCicloEscolar, getSizeList } =
    useCumplimientoTrabajoContext();

  useEffect(() => {
    const fetchGetCiclo = async () => {
      await Promise.all([getCicloEscolar(), getSizeList()]);
    };
    fetchGetCiclo();
  }, [getCicloEscolar, getSizeList]);


  if (isCicloLoading) return <p>Cargando Ciclo Escolar...</p>;
  return (
    <>
      <div className={styles.container}>
        <div className={`${styles.filter} ${styles.marginBottom}`}>
          <label>CICLO ESCOLAR: </label>
          <span>{currentCiclo}</span>
        </div>
      </div>
      {currentCiclo && (
        <>
        <FiltroCargaCumplimientoTrabajo />
        <TablaCumplimientoTrabajo />
        </>
      )}
    </>
  );
}

export default MenuPrincipal;
