import React, { useCallback, useState } from 'react'
import api from '../../../../hooks/api';

const useFetchCicloEscolar = () => {
    const [currentCiclo, setCurrentCiclo] = useState();
    const [isCicloLoading, setIsCicloLoading] = useState(true);
    
    const getCicloEscolar = useCallback(async () => {
        try {
            const response = await api.get('api/v1/jefe_depto/get-ciclo-escolar-actual');
            console.log(response.data);
            setCurrentCiclo(response.data);
        } catch (error) {
            console.log(error);
            throw error;
        } finally {
            setIsCicloLoading(false);
        }
    }, []);
  return {
    currentCiclo,
    isCicloLoading,
    getCicloEscolar
  }
}

export default useFetchCicloEscolar
