import axios from "axios";

export const saveBreaches = async() => {
  try {
    const response = await axios.post("");
    if (response.status === 200) {
      return response.data;
    }

    throw new Error("Error al guardar los incumplimientos");
  } catch (error) {
    throw new Error("Error al guardar los incumplimientos");
  }
};
