import React, { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faUpload, faWarning } from '@fortawesome/free-solid-svg-icons';
import Loading from '../../../../reutilizable/Loading';
import { cambiarNombreArchivo } from '../../../../utils/index';
import { subirRequisitosExpediente } from '../services/requisitosExpedienteService';
import { subirDocCriterioExpediente } from '../services/criteriosExpedienteService';
import styles from './styles/CargarEvidencia.module.css';

/**
 * Componente CargarEvidencia para subir un documento ya sea de requisitos o criterios.
 *
 * @param {Object} props - Props del componente.
 * @param {'criterio' | 'requisito'} props.tipoUpload - Tipo de accion (subir) que hara el componente
 * @param {Number} props.idExpediente - Id del criterio || requisito que se eliminara su respectivo documento
 * @param {Number} props.idSolicitud - Id de la solicitud del academico
 *
 * @returns {JSX.Element} Formulario para subir un documento.
 */
const CargarEvidencia = ({ 
  tipoUpload, idExpediente, idSolicitud, onUploadSuccess
}) => {
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const { register, handleSubmit, reset, clearErrors, setError, control, formState: { isSubmitted, errors } } = useForm();
  const archivo = useWatch({ control, name: 'archivo' });

  const validarArchivo = (archivo) => {
    if (!archivo) return true;

    // Validar tipo de archivo
    if (!archivo.type.includes('pdf')) {
      return 'Solo se permiten archivos PDF';
    }

    // Validar tamaño (2MB máximo)
    const tamañoMaximo = 2 * 1024 * 1024;
    if (archivo.size > tamañoMaximo) {
      return 'El archivo no debe superar los 2MB';
    }

    return true;
  };

  const handleCargarEvidencia = async (data) => {
    clearErrors();
    setIsSuccess(false);

    const file = data.archivo[0]; // Obtener el archivo del formulario
    if (!file) {
      setError('archivo', {
        type: 'required',
        message: 'Debes seleccionar un archivo'
      })
    }

    try {
      setLoading(true);

      // Generar un nombre único para el archivo
      const fileName = data.archivo[0].name;
      const uniqueName = Math.floor(Math.random() * 999999);
      const nombreArchivo = cambiarNombreArchivo(fileName, uniqueName);

      const formData = new FormData();
      formData.append('idSolicitud', Number(idSolicitud));
      formData.append('idExpediente', Number(idExpediente))
      formData.append('archivo', file, nombreArchivo);

      if (tipoUpload === 'requisito') {
        await subirRequisitosExpediente(formData)
          .then(response => {
            if (response.status === 200) {
              setIsSuccess(true);
              onUploadSuccess()
            }
            reset(data.archivo);
            return;
          })
          .catch(error => console.error(`Error al subir el documento al requisito con id ${idExpediente}`, error))
      }

      if (tipoUpload === 'criterio') {
        await subirDocCriterioExpediente(formData)
         .then(response => {
            if (response.status === 200) {
              setIsSuccess(true);
              onUploadSuccess()
            }
            reset(data.archivo);
            return;
         })
         .catch(error => console.error(`Error al subir el documento al criterio con id ${idExpediente}`, error))
      }

    } catch (error) {
      console.error('Error al subir el archivo:', error);
      setIsSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validacionResultado = validarArchivo(file);
      if (validacionResultado !== true) {
        setError('archivo', { type: 'validation', message: validacionResultado });
      } else {
        clearErrors('archivo');
      }
    }
  };

  return (
    <form
      className={styles.formCargarEvidencia}
      onSubmit={handleSubmit(handleCargarEvidencia)}
    >
      <div className={styles.containerInput}>
        <input
          type='file'
          accept="application/pdf"
          className={styles.inputFile}
          disabled={isSuccess}
          {...register('archivo', {
            onChange: handleFileChange,
            validate: {
              required: (value) => value?.length > 0 || 'Seleccione un archivo',
              validarTipo: (value) =>
                value?.[0] ? validarArchivo(value[0]) : true
            }
          })}
        />
        <FontAwesomeIcon
          icon={faUpload}
          color={isSuccess ? 'gray' : 'purple'}
          size='xl'
          cursor={'pointer'}
        />
      </div>

      {archivo?.[0] && !isSuccess && (
        <input
          type='submit'
          value={'SubirEvidencia'}
          disabled={isSubmitted || errors.archivo}
          className={styles.inputSubirEvidencia}
        />
      )}

      {loading && (<Loading />)}

      {errors.archivo && (
        <FontAwesomeIcon
          icon={faWarning}
          color={'red'}
          size='2x'
          cursor={'pointer'}
          title={errors.archivo.message}
        />
      )}

      {isSuccess && (
        <FontAwesomeIcon
          icon={faCheckCircle}
          color='green'
          size='2x'
          title='Documento guardado exitosamente'
        />
      )}
    </form>
  );
};

export default CargarEvidencia;
