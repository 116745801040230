import axios from "axios";
import { getAccessToken } from "../../../../../features/authService";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const API_TEACHERS_URL = `${API_BASE_URL}/api/v1/jefe_depto/lista-profesores-asignacion-carga-horaria`;
const API_CICLO_ACTUAL_URL = `${API_BASE_URL}/api/v1/jefe_depto/get-ciclo-escolar-actual`;
const API_SIZE_LIST_URL = `${API_BASE_URL}/api/v1/jefe_depto/reporte/all-plan-trabajo-size`;
const API_REPORT_PT = `${API_BASE_URL}/api/v1/jefe_depto/reporte/all-plan-trabajo`;
const API_DETALLE_PT = `${API_BASE_URL}/api/v1/jefe_depto/reporte/get-plan-trabajo`;
export const obtenerDocentes = async (codigoEmpleado, cicloEscolar, page, app) => {
  try {
    const token = await getAccessToken();

    const response = await axios.post(
      API_TEACHERS_URL,
      {
        codigoEmpleado,
        cicloEscolar,
        page,
        app,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Backend error response:", error.response.data);
    return;
  }
};

export const obtenerCicloActual = async () => {
  try {
    const token = await getAccessToken();
    const response = await axios.get(API_CICLO_ACTUAL_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener ciclo actual:", error);
  }
};

export const obtenerSizeList = async (cicloEscolar, jefeDepto) => {
  try {
    const token = await getAccessToken();
    const response = await axios.get(API_SIZE_LIST_URL + `?cicloEscolar=${cicloEscolar}&jefeDepto=${jefeDepto}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error al obtener el tamaño de la lista:", error);
  }
};

export const getReportPT = async (page, cicloEscolar, jefeDepto) => {
  try {
    const token = await getAccessToken();
    const response = await axios.get(
      API_REPORT_PT + `?pagina=${page}&jefeDepto=${jefeDepto}&cicloEscolar=${cicloEscolar}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Backend error response:", error.response.data);
    return;
  }
};

export const getDetallePT = async (id, codigo) => {
  try {
    const token = await getAccessToken();
    const response = await axios.get(API_DETALLE_PT + `?solicitudID=${id}&codigo=${codigo}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Backend error response:", error.response.data);
    return;
  }
};
