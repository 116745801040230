import React from "react";
import styles from "./planTrabajoPage.module.css";
import FormularioPlan from "../components/FormularioPlan";
import usePlanTrabajo from "../hooks/usePlanTrabajo";

const PlanTrabajoPage = () => {
  const { datosAcademico, handleChange, obtainPlanTrabajo } = usePlanTrabajo();

  return (
    <div>
      <h2 className={styles.titulo}>Plan de Trabajo</h2>
      <FormularioPlan
        datosAcademico={datosAcademico}
        handleChange={handleChange}
        obtainPlanTrabajo={obtainPlanTrabajo}
      />
    </div>
  );
};

export default PlanTrabajoPage;
