import InfoLeft from "./components/InfoLeft/InfoLeft";
import InfoRight from "./components/InfoRight/InfoRight";
import { useIncumplimientosAcademico } from "./hooks/useIncumplimientosAcademico";
import styles from "./styles/IncumplimientosAcademico.module.css";

const IncumplimientosAcademico = () => {
  const {
    codeAcademico,
    handleCodeAcademico,
    infoAcademico,
    addInfoAcademico,
  } = useIncumplimientosAcademico();

  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Incumplimientos de académicos</h2>

      <div className={styles.containerInfo}>
        <InfoLeft
          handleCOdeAcademico={handleCodeAcademico}
          codeAcademico={codeAcademico}
          addInfoAcademico={addInfoAcademico}
        />

        {infoAcademico ? (
          <InfoRight />
        ) : (
          <p className={styles.textAlert}>
            Introduzca el código del académico y oprima el botón "Continuar".
          </p>
        )}
      </div>
    </div>
  );
};
export default IncumplimientosAcademico;
