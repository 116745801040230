import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import useSWR from 'swr';
import Alert from '../../../reutilizable/Alert';
import { useEvaluationStore } from '../../../store/useEvaluationStore';
import { getComentarios, postComentario, putComentario } from '../services/notasService';
import styles from './styles/Notas.module.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_OBTENER_COMENTARIOS_URL = `${API_BASE_URL}/api/v1/evaluacion/obtener-comentarios`;

const Notas = () => {
  const { selectedDataAcademico } = useEvaluationStore();
  const [fetch, setFetch] = useState(API_OBTENER_COMENTARIOS_URL);
  
  const [allComentarios, setAllComentarios] = useState([]);
  const fetcher = () => getComentarios(selectedDataAcademico?.id);
  const { data: comentarios } = useSWR(fetch, fetcher, {
  });

  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    defaultValues: {
      comentario: '',
    }
  });

  const [mensajeAlerta, setMensajeAlerta] = useState({
    type: null,
    mensaje: null,
  });
  const [isVisible, setIsVisible] = useState(false);

  const handleSubmitComentario = async (data) => {
    const body = { ...data, idSolicitud: selectedDataAcademico?.id || '' };
    setFetch(null);
    await postComentario(body)
      .then(response => {
        if (response.status === 200) {
          setIsVisible(!isVisible);
          setFetch(API_OBTENER_COMENTARIOS_URL);
          setMensajeAlerta({
            type: 'success',
            mensaje: 'Comentario agregado correctamente',
          });
          reset();
        }
      })
      .catch(error => console.error('Error al agregar comentario: ', error))
  }

  const handleLeerNota = async () => {
    const body = { idSolicitud: selectedDataAcademico?.id || '' };
    await putComentario(body)
      .then(response => {
        setIsVisible(!isVisible);
        setMensajeAlerta({
          type: 'success',
          mensaje: response.mensaje,
        });
        console.log(response.mensaje)
      })
      .catch(error => console.error('Error al leer la nota: ', error))
  };

  useEffect(() => {
    const timer = setTimeout(() => { setIsVisible(false) }, 2000);
    return () => clearTimeout(timer); // Limpia el temporizador al desmontar
  }, [isVisible])

  const VALIDATION = {
    comentario: {
      required: 'Debe escribir un comentario',
      minLength: { value: 5, message: 'El mensaje debe tener al menos 5 caracteres' },
    },
  };

  useEffect(() => {
    setAllComentarios(comentarios);
  }, [comentarios])
  

  return (
    <div className={styles.containerNotas}>
      <p className={styles.tituloNotas}>
        <FontAwesomeIcon icon={faAngleRight} color={'yellow'} /> {''}
        Puede agregar tantas entradas como desee
      </p>

      <div className={styles.containerEntradas}>
        {comentarios?.map(comentario => (
          <div key={comentario.id} className={styles.containerEntradaComentario}>
            <p>
              <span>[{comentario.fechaFormato} - {comentario.usuario}]</span>
              {comentario.comentario}
            </p>
          </div>
        ))}
      </div>

      <form action="" onSubmit={handleSubmit(handleSubmitComentario)}>
        <div className={styles.containerAgregarEntrada}>
          <div className={styles.containerTextareaEntrada}>
            <p>Agregar entrada</p>
            <textarea
              rows={6}
              className={errors.comentario ?? `${styles.containerTextareaEntradaError} : ''`}
              {...register('comentario', VALIDATION.comentario)}
            ></textarea>
          </div>

          {errors.comentario && (
            <Alert typeAlert={'error'}>
              <p>{errors.comentario.message}</p>
            </Alert>
          )}

          {isVisible && (
            <Alert typeAlert={mensajeAlerta.type}>
              <p>{mensajeAlerta.mensaje}</p>
            </Alert>
          )}

          <div className={styles.buttonsTextareaEntrada}>
            <button type='submit' className={styles.btnAgregarComentario}>Agregar</button>
            <button
              type='button'
              className={styles.btnComentarioLeido}
              onClick={handleLeerNota}
            >Comentario leído</button>
            <button type='button' className={styles.btnCancelar}>Cancelar</button>
          </div>
        </div>
      </form>

    </div>
  )
}

export default Notas;
