import React, { createContext, useEffect, useState } from "react";
import useFetchCicloEscolar from "../hooks/useFetchCicloEscolar";
import useFetchSizeListProfesores from "../hooks/useFetchSizeListProfesores";
import useFetchProfesoresList from "../hooks/useFetchProfesoresList";

export const CumplimientoTrabajoContext = createContext();

const CumplimientoTrabajoProvider = ({ children }) => {
  const { currentCiclo, getCicloEscolar, isCicloLoading } =
    useFetchCicloEscolar();
  const { sizeListProfesores, getSizeList, isSizeListLoading } =
    useFetchSizeListProfesores();
  const { getProfesoresList, profesoresList, isProfesoresLoading } =
    useFetchProfesoresList();

    const [selectedMunicipio, setSelectedMunicipio] = useState();

  return (
    <CumplimientoTrabajoContext.Provider
      value={{
        currentCiclo,
        getCicloEscolar,
        isCicloLoading,
        sizeListProfesores,
        getSizeList,
        isSizeListLoading,
        getProfesoresList,
        profesoresList,
        isProfesoresLoading,
        selectedMunicipio,
      }}
    >
      {children}
    </CumplimientoTrabajoContext.Provider>
  );
};

export default CumplimientoTrabajoProvider;
