import React, { useState, useEffect } from "react";
import Paginacion from "../../../../../reutilizable/Paginacion";
import FiltroValidacionPlanTrabajo from "../components/FiltroValidacionPlanTrabajo";
import TablaPlanTrabajoDetalles from "../components/TablaPlanTrabajoDetalles";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";

import useMunicipios from "../../../../../hooks/useMunicipios";
import useListPlanTrabajo from "../hooks/useListPlanTrabajo";
import usePagination from "../../../../../hooks/usePagination";
import styles from "../styles/TablaPlanTrabajo.module.css";

const TablaPlanTrabajo = ({ planTrabajo, setPlanTrabajo, userInfo }) => {
  const { municipios } = useMunicipios();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [filteredPlanTrabajos, setFilteredPlanTrabajos] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [estatusFilter, setEstatusFilter] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedMunicipio, setSelectedMunicipio] = useState("");
  const [currentCiclo, setCurrentCiclo] = useState("");

  const {
    currentPage,
    itemsPerPage,
    totalPages,
    currentItems,
    handleFirstPage,
    handleLastPage,
    handlePreviousPage,
    handleNextPage,
  } = usePagination(filteredPlanTrabajos, totalItems);

  const { fetchCicloActual, fetchTotalSize, fetchReportPlanTrabajo, isLoading } = useListPlanTrabajo(
    planTrabajo,
    currentCiclo,
    currentPage,
    userInfo,
    setFilteredPlanTrabajos
  );

  useEffect(() => {
    const fetchCicloEscolarActual = async () => {
      const cicloActual = await fetchCicloActual();
      setCurrentCiclo(cicloActual);
    };

    fetchCicloEscolarActual();
  }, [fetchCicloActual]);

  useEffect(() => {
    const updateMaterias = async () => {
      if (!currentCiclo) return;

      const total = await fetchTotalSize();
      setTotalItems(total);
     fetchReportPlanTrabajo();
    };

    updateMaterias();
  }, [currentCiclo, currentPage, fetchReportPlanTrabajo, fetchTotalSize, itemsPerPage]);

  useEffect(() => {
    const filteredData = planTrabajo.filter((docente) => {
      const matchesStatus = estatusFilter ? docente.estatus === estatusFilter : true;
      const matchesSearch =
        docente.codigoProfesor.toLowerCase().includes(searchTerm.toLowerCase()) ||
        docente.nombreProfesor.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesStatus && matchesSearch;
    });

    setFilteredPlanTrabajos(filteredData);
  }, [planTrabajo, estatusFilter, searchTerm]);

  const handleMunicipioChange = (newMunicipio) => {
    const updatedplanTrabajo = planTrabajo.map((docente) => ({
      ...docente,
      ubicacion: Number(newMunicipio),
    }));
    setPlanTrabajo(updatedplanTrabajo);
    setSelectedMunicipio(newMunicipio);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleStatusChange = (value) => {
    setEstatusFilter(value);
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });

    const sortedData = [...filteredPlanTrabajos].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setFilteredPlanTrabajos(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? <FaArrowUp /> : <FaArrowDown />;
    }
    return null;
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.filter} ${styles.marginBottom}`}>
        <label>CICLO ESCOLAR: </label>
        <span>{currentCiclo}</span>
      </div>
      {isLoading ? (
        <p>Cargando datos...</p>
      ) : (
        currentCiclo && (
          <>
            <FiltroValidacionPlanTrabajo
              searchTerm={searchTerm}
              handleSearchChange={handleSearchChange}
              municipios={municipios}
              selectedMunicipio={selectedMunicipio}
              handleMunicipioChange={handleMunicipioChange}
              selectedStatus={estatusFilter}
              handleStatusChange={handleStatusChange}
            />

            <TablaPlanTrabajoDetalles
              currentItems={currentItems}
              municipios={municipios}
              cicloDisponible={!!currentCiclo}
              getSortIcon={getSortIcon}
              handleSort={handleSort}
            />

            <Paginacion
              currentPage={currentPage}
              totalPages={totalPages}
              onFirstPage={handleFirstPage}
              onLastPage={handleLastPage}
              onPreviousPage={handlePreviousPage}
              onNextPage={handleNextPage}
            />
          </>
        )
      )}
    </div>
  );
};

export default TablaPlanTrabajo;
