import axios from 'axios';
import { getAccessToken } from '../../authService';

// Obtener la URL base desde las variables de entorno
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_VISOR_DOCUMENTO = `${API_BASE_URL}/api/v1/evaluacion/documento-visor`;

export const visorDocumentosService = async (urlDocumento) => {
  try {
    const token = await getAccessToken();
    const response = await axios.get(`${API_VISOR_DOCUMENTO}?nodo=${urlDocumento}`, {
      headers: {
        Accept: '*/*',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer'
    })
    return response;
  } catch (error) {
    console.error('Error al obtener el documento: ', error);
    throw error;
  }
};