import { useState } from "react";

export const useIncumplimientosAcademico = () => {
  const [codeAcademico, setCodeAcademico] = useState("");
  const [infoAcademico, setInfoAcademico] = useState(false);

  const handleCodeAcademico = (e) => {
    const onlyNumber = e.target.value.replace(/[^0-9]/g, "");
    setCodeAcademico(onlyNumber);
  };

  const addInfoAcademico = () => {
    setInfoAcademico(true);
  };

  return {
    codeAcademico,
    infoAcademico,
    handleCodeAcademico,
    addInfoAcademico,
  };
};
