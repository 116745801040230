import React from "react";
import styles from "../css/FiltroCargaEstudiante.module.css";

const FiltroCargaEstudiante = ({
  municipios,
  selectedMunicipio,
  handleMunicipioChange,
  cicloDisponible,
  currentPage,
}) => {
  return (
    <div className={styles.searchAndMunicipio}>
      <div className={styles.municipioFilterContainer}>
        <label htmlFor="municipioSelector">Municipio: </label>
        <select
          id="municipioSelector"
          value={selectedMunicipio}
          onChange={(e) => handleMunicipioChange(e.target.value, currentPage)}
          disabled={!cicloDisponible}
        >
          <option value="">Seleccione un municipio</option>
          {municipios.map((municipio) => (
            <option key={municipio.id} value={municipio.municipio}>
              {municipio.municipio}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default FiltroCargaEstudiante;
