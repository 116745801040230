import { useState } from 'react';
import { actualizarMunicipioCargaHoraria } from '../services/updateMunicipio';

const useUpdateMunicipio = (municipios, selectedCiclo, filteredMaterias, setFilteredMaterias, userInfo) => {
  const [municipioError, setMunicipioError] = useState(null);

  const handleMunicipioChange = async (newMunicipio) => {
    if (newMunicipio) {
      aplicarMunicipioATodas(newMunicipio);
      try {
        await actualizarMunicipioCargaHoraria({
          codigoEmpleado: userInfo,
          cicloEscolar: selectedCiclo,
          page: 1,
          app: "Prueba",
          idMunicipio: municipios.find((municipio) => municipio.id.toString() === newMunicipio.toString())?.id || ""
        });
        console.log("Municipio de la carga horaria actualizado correctamente.");
      } catch (error) {
        console.error("Error al actualizar el municipio de la carga horaria:", error);
        setMunicipioError(error);
      }
    }
  };

  const aplicarMunicipioATodas = (municipio) => {
    if (municipio) {
      const updatedMaterias = filteredMaterias.map((materia) => ({
        ...materia,
        ubicacion: municipio,
        statusMunicipio: "0"
      }));
      setFilteredMaterias(updatedMaterias);
    }
  };

  return { handleMunicipioChange, municipioError };
};

export default useUpdateMunicipio;
