import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useEvaluationStore } from '../../../store/useEvaluationStore';
import ViewerPDF from '../../../reutilizable/ViewerPDF';
import Loading from '../../../reutilizable/Loading';
import Modal from '../../../reutilizable/Modal';
import Table from '../../../reutilizable/Table';
import styles from './styles/CotejoRequisitosPage.module.css';

const requisitosJSON = [
  {
    "id": 1,
    "nombre": "Ser profesor de carrera o técnico académico de tiempo completo con categoría de titular o asociado en cualquiera de sus niveles",
    "romano": "I",
    "nodo": null,
    "orden": 1,
    "satisfecho": false
  },
  {
    "id": 2,
    "nombre": "Haber impartido al menos 8 ocho horas-semana-mes por semestre de docencia en la Universidad de Guadalajara (ciclo escolar 2024-A), en cursos curriculares",
    "romano": "II",
    "nodo": null,
    "orden": 2,
    "satisfecho": false
  },
  {
    "id": 3,
    "nombre": "Haber impartido al menos 8 ocho horas-semana-mes por semestre de docencia en la Universidad de Guadalajara (ciclo escolar 2024-B), en cursos curriculares",
    "romano": "III",
    "nodo": null,
    "orden": 3,
    "satisfecho": false
  },
  {
    "id": 4,
    "nombre": "Haber cumplido al menos el 90% de las asistencias en los cursos curriculares impartidos en el período a evaluar 2024 \"A\"",
    "romano": "IV",
    "nodo": null,
    "orden": 4,
    "satisfecho": false
  },
  {
    "id": 5,
    "nombre": "Haber cumplido al menos el 90% de las asistencias en los cursos curriculares impartidos en el período a evaluar 2024 \"B\"",
    "romano": "V",
    "nodo": null,
    "orden": 5,
    "satisfecho": false
  },
  {
    "id": 6,
    "nombre": "Estar cubriendo actualmente (ciclo escolar 2025-A) una carga horaria de docencia curricular por lo menos de 8 ocho horas-semana-mes",
    "romano": "VI",
    "nodo": null,
    "orden": 6,
    "satisfecho": false
  },
  {
    "id": 7,
    "nombre": "Tener registrado su plan de trabajo anual (enero-diciembre de 2025) en el departamento de adscripción. El plan deberá ser congruente con el propio plan de desarrollo de su dependencia de adscripción y comprometerse por escrito a su cumplimiento",
    "romano": "VII",
    "nodo": null,
    "orden": 7,
    "satisfecho": false
  },
  {
    "id": 8,
    "nombre": "aber cumplido en su totalidad con el plan de trabajo que presentó en la promoción anterior (enero-diciembre de 2024) de este programa, en caso de haber sido beneficiado",
    "romano": "VIII",
    "nodo": null,
    "orden": 8,
    "satisfecho": false
  },
  {
    "id": 9,
    "nombre": "Contar por lo menos con el grado académico de maestro o el diploma de especialidad, de al menos 2 años, este último será aplicable únicamente para el personal docente de las áreas de ciencias de la salud",
    "romano": "IX",
    "nodo": null,
    "orden": 9,
    "satisfecho": false
  },
  {
    "id": 10,
    "nombre": "No trabajar más de ocho horas semanales prestando servicios personales, subordinados o independientes, en instituciones o empresas propias o ajenas",
    "romano": "X",
    "nodo": null,
    "orden": 10,
    "satisfecho": false
  }
]

const CotejoRequisitosPage = () => {

  const { selectedDataAcademico } = useEvaluationStore();

  const [urlPDF, setUrlPDF] = useState(null);
  const [requisitos, _] = useState(requisitosJSON);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cabecerasTable, _set] = useState([
    {
      id: 1,
      labelCabecera: 'Id'
    },
    {
      id: 2,
      labelCabecera: 'Requisito'
    },
    {
      id: 3,
      labelCabecera: 'Ver Evidencia'
    },
    {
      id: 4,
      labelCabecera: 'Cotejar'
    }
  ]);

  const { register, handleSubmit } = useForm();

  const handleSubmitCotejo = (data) => {
    console.log(data);
  };

  const openModal = () => setIsModalOpen(!isModalOpen);
  const closeModal = () => setIsModalOpen(!isModalOpen);

  return (
    <div className={styles.containerCotejoRequisitos}>
      <div className={styles.containerTablaRequisitos}>
        <Modal isOpen={isModalOpen} onClose={closeModal} width='750px'>
          <ViewerPDF urlPdf={urlPDF} />
        </Modal>

        <form 
          onSubmit={handleSubmit(handleSubmitCotejo)}
          className={styles.formCotejoDocs}
        >
          <Table cabecerasTable={cabecerasTable}>
            {requisitos?.length
              ? (
                requisitos?.map(requisito => {
                  return (
                    <tr key={requisito.id}>
                      <td>{requisito.romano}</td>
                      <td>
                        <label htmlFor={requisito.id} className={styles.label}>
                          {requisito.nombre}
                        </label>
                      </td>
                      <td className={styles.tdTablaCotejoRequisitos}>
                        <div className={styles.containerBtnPDF}>
                          <button
                            type="button"
                            className={styles.buttonPdf}
                            title="Ver PDF"
                            onClick={() => {
                              openModal();
                              setUrlPDF(requisito.nodo || '');
                            }}
                          >
                            <FontAwesomeIcon icon={faFilePdf} color="red" />
                          </button>
                        </div>
                      </td>
                      <td className={styles.tdCheckCotejo}>
                        <input
                          type='checkbox'
                          className={`checkbox_green ${styles.checkCotejo}`}
                          id={requisito.id}
                          { ...register(`${requisito.id}`) }
                        />
                      </td>
                    </tr>
                  )
                })
              ) : (
                <Loading />
              )}
          </Table>

          <div className={styles.containerSubmitCotejo}>
            <input 
              type="submit"
              value="Cotejar"
              className={styles.submitCotejo}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CotejoRequisitosPage;