import React, { createContext, useState } from "react";
import useFetchGetListProfesores from "../hooks/useFetchGetListProfesores";
import useFetchUpdateOficio from "../hooks/useFetchUpdateOficio";
import useMunicipios from "../../../../hooks/useMunicipios";
import useFetchDeleteOficio from "../hooks/useFetchDeleteOficio";
import useFetchAltaOficio from "../hooks/useFetchAltaOficio";
import { consultarCartaDesempeno } from "../../../CartaDesempenoService";
import { updateMunicipiosEvaluacionDocente } from "../service/updateMunicipios";

export const EvaluacionEstudianteContext = createContext();

const EvaluacionEstudianteProvider = ({ children }) => {
  const {
    getListProfesores,
    isLoadingProfesores,
    listaProfesores,
    setListaProfesores,
  } = useFetchGetListProfesores();
  const { updateOficio } = useFetchUpdateOficio();
  const { municipios } = useMunicipios();
  const { deleteOficioByIdQr } = useFetchDeleteOficio();
  const [profesoresCopies, setProfesoresCopies] = useState([]);
  const { altaOficioProfesor } = useFetchAltaOficio();
  const [cicloEscolar, setCicloEscolar] = useState();
  const [selectedMunicipio, setSelectedMunicipio] = useState("");

  const updateMateriasProfesor = (profesorToEdit, materias) => {
    setListaProfesores(
      listaProfesores.map((profesor) => {
        if (profesor.codigoProfesor === profesorToEdit.codigoProfesor) {
          return {
            ...profesor,
            materia: materias,
          };
        } else return profesor;
      })
    );
  };

  const handleMunicipioChange = async (municipio, currentPage) => {
    const confirmChangeMunicipio = window.confirm(
      "¿Estas seguro de que deseas cambiar el municipio de todos los campos?"
    );

    if (confirmChangeMunicipio) {
      try {
        const response = await updateMunicipiosEvaluacionDocente({
          codigoEmpleado: localStorage.getItem("userName"),
          cicloEscolar: cicloEscolar,
          page: currentPage,
          app: "PruebasSpertoDigital",
          idMunicipio: municipios.find((item) => item.municipio === municipio)
            .id,
        });
        setListaProfesores(
          listaProfesores.map((profesor) => ({
            ...profesor,
            ubicacion: municipio,
          }))
        );
        setSelectedMunicipio(municipio);
      } catch (error) {
        console.error(error);
      }
    } else {
      console.log("Cancelando");
      return;
    }
  };

  const editProfesor = (profesorToEdit) => {
    setListaProfesores(
      listaProfesores.map((profesor) => {
        if (profesor.codigoProfesor === profesorToEdit.codigoProfesor) {
          return {
            ...profesor,
            isEdit: true,
          };
        } else return profesor;
      })
    );
    setProfesoresCopies([...profesoresCopies, profesorToEdit]);
  };

  const wasProfesorEdited = (profesor, originalProfesor) => {
    let materiasChanged = false;
    if (!originalProfesor.materia && profesor.materia) {
      materiasChanged = true;
    } else if (originalProfesor.materia) {
      originalProfesor.materia.forEach((originalMateria, idx) => {
        if (
          originalMateria.resultado !== profesor.materia[idx].resultado &&
          !materiasChanged
        ) {
          materiasChanged = true;
        }
      });
    }
    return (
      profesor.noOficio !== originalProfesor.noOficio ||
      materiasChanged ||
      profesor.ubicacion !== originalProfesor.ubicacion
    );
  };

  const saveEditProfesor = (profesorToEdit) => {
    setListaProfesores(
      listaProfesores.map((profesor) => {
        if (profesor.codigoProfesor === profesorToEdit.codigoProfesor) {
          return {
            ...profesor,
            isEdit: false,
            hasChanged: wasProfesorEdited(
              profesorToEdit,
              profesoresCopies.find(
                (prof) => prof.codigoProfesor === profesorToEdit.codigoProfesor
              )
            ),
          };
        } else return profesor;
      })
    );
  };

  const handleNoOficioChange = (value, profesorToEdit) => {
    setListaProfesores(
      listaProfesores.map((profesor) => {
        if (profesor.codigoProfesor === profesorToEdit.codigoProfesor) {
          return {
            ...profesor,
            noOficio: value,
          };
        } else return profesor;
      })
    );
  };

  const generateNewPdf = async (profesorToEdit) => {
    delete profesorToEdit.isEdit;
    delete profesorToEdit.hasChanged;
    const response = await altaOficioProfesor({
      ...profesorToEdit,
      ubicacion: municipios.find(
        (item) => item.municipio === profesorToEdit.ubicacion
      ).id,
    });
    setListaProfesores(
      listaProfesores.map((profesor) => {
        if (profesor.codigoProfesor === profesorToEdit.codigoProfesor) {
          return {
            ...profesor,
            marcado: "1",
          };
        } else return profesor;
      })
    );
  };

  const updatePdf = async (profesorToEdit) => {
    delete profesorToEdit.isEdit;
    delete profesorToEdit.hasChanged;
    const response = await updateOficio({
      ...profesorToEdit,
      ubicacion: municipios.find(
        (item) => item.municipio === profesorToEdit.ubicacion
      ).id,
    });
    setListaProfesores(
      listaProfesores.map((profesor) => {
        if (profesor.codigoProfesor === profesorToEdit.codigoProfesor) {
          return {
            ...profesor,
            hasChanged: false,
          };
        } else return profesor;
      })
    );
  };

  const downloadPdf = async (profesor) => {
    const response = await consultarCartaDesempeno(profesor.idQr);
  };

  const handleDeleteProfesor = async (idQr, currentPage) => {
    const confirmDelete = window.confirm(
      "¿Estas seguro que deseas eliminar el registro?"
    );
    if (confirmDelete) {
      try {
        const response = await deleteOficioByIdQr(idQr);
        await getListProfesores({
          codigoEmpleado: localStorage.getItem("userName"),
          cicloEscolar: cicloEscolar,
          page: currentPage,
          app: "PruebasSpertoDigital",
        });
        return;
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Cancenlando");
      return;
    }
  };

  return (
    <EvaluacionEstudianteContext.Provider
      value={{
        getListProfesores,
        isLoadingProfesores,
        listaProfesores,
        setListaProfesores,
        updateOficio,
        municipios,
        deleteOficioByIdQr,
        updateMateriasProfesor,
        handleMunicipioChange,
        editProfesor,
        handleDeleteProfesor,
        wasProfesorEdited,
        saveEditProfesor,
        handleNoOficioChange,
        generateNewPdf,
        updatePdf,
        downloadPdf,
        setCicloEscolar,
        cicloEscolar,
        selectedMunicipio,
        setSelectedMunicipio,
      }}
    >
      {children}
    </EvaluacionEstudianteContext.Provider>
  );
};

export default EvaluacionEstudianteProvider;
