// RoleSelectionModal.js
import React, { useState, useEffect } from 'react';
import styles from './styles/RoleSelectionModal.module.css';
import { loginRoles } from '../features/authService';

const RoleSelectionModal = ({ open, roles, onClose, onConfirmRole }) => {
    const [selectedFormattedRole, setSelectedFormattedRole] = useState('');
    const [roleMappings, setRoleMappings] = useState({});

    useEffect(() => {
        if (roles && roles.length > 0) {
            // Crear un mapeo entre los roles originales y los roles formateados
            const mappings = roles.reduce((acc, role) => {
                acc[role] = formatRole(role);
                return acc;
            }, {});

            // Ordenar el mapeo alfabéticamente según el valor formateado
            const sortedMappings = Object.fromEntries(
                Object.entries(mappings).sort(([, a], [, b]) => a.localeCompare(b, 'es', { sensitivity: 'base' }))
            );

            setRoleMappings(sortedMappings);
        }
    }, [roles]);

    // Función de ayuda para formatear los roles para la visualización
    const formatRole = (role) => {
        switch (role.toLowerCase()) {
            case "jefe_depto":
                return "Jefe de Departamento";
            case "admin_gral":
                return "Administración General";
            case "comision_dictaminadora_cu_ep":
                return "Comisión Dictaminadora CUEP";
            case "academico":
                return "Académico";
            default:
                return role; // Devolver tal cual si no está en la lista
        }
    };

    // Manejar el envío de la selección de rol
    const handleRoleSubmit = async () => {
        try {
            const token = localStorage.getItem('accessToken');

            // Obtener la clave original del rol a partir del mapeo
            const originalRole = Object.keys(roleMappings).find(
                (key) => roleMappings[key] === selectedFormattedRole
            );

            // Llamar a loginRoles para obtener nuevos tokens basados en el rol seleccionado
            const { roles: newRoles, rolesAsArray } = await loginRoles(token, originalRole);

            if (newRoles && rolesAsArray) {
                // Confirmar el rol y proceder con la redirección
                onConfirmRole(originalRole);
            }

            onClose(); // Cerrar el modal tras la selección exitosa
        } catch (error) {
            console.error('Error al enviar el rol:', error);
            alert('Ocurrió un error al seleccionar el rol. Inténtelo de nuevo.');
        }
    };

    if (!open) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                <h2 className={styles.modalTitle}>Selecciona un Rol</h2>
                <select
                    className={styles.select}
                    value={selectedFormattedRole}
                    onChange={(e) => setSelectedFormattedRole(e.target.value)}
                >
                    <option value="">-- Seleccionar Rol --</option>
                    {Object.values(roleMappings).map((formattedRole, index) => (
                        <option key={index} value={formattedRole}>
                            {formattedRole}
                        </option>
                    ))}
                </select>
                <button
                    className={styles.submitButton}
                    onClick={handleRoleSubmit}
                    disabled={!selectedFormattedRole} // Deshabilitar si no se ha seleccionado un rol
                >
                    Guardar
                </button>
            </div>
        </div>
    );
};

export default RoleSelectionModal;
