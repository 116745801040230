import React, { useState } from "react";
import styles from "../css/TablaCargaEstudianteDetalle.module.css";
import MateriaModal from "./MateriaModal";
import useEvaluacionEstudianteContext from "../context/useEvaluacionEstudianteContext";

const TablaMateriasEstudianteDetalle = ({
  cicloDisponible,
  getSortIcon,
  handleSort,
  currentPage,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [profesorData, setProfesorData] = useState({});
  const {
    listaProfesores,
    handleNoOficioChange,
    saveEditProfesor,
    editProfesor,
    generateNewPdf,
    updatePdf,
    downloadPdf,
    handleDeleteProfesor,
  } = useEvaluacionEstudianteContext();

  const handleRowDoubleClick = (profesor) => {
    setProfesorData(profesor);
    setModalIsOpen(true);
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr>
          <th onClick={() => handleSort("codigoProfesor")}>
            CÓDIGO PROFESOR {getSortIcon("codigoProfesor")}
          </th>
          <th onClick={() => handleSort("nombreProfesor")}>
            NOMBRE DOCENTE {getSortIcon("nombreProfesor")}
          </th>
          <th>NO. OFICIO</th>
          <th>MUNICIPIO</th>
          <th>CICLO ESCOLAR</th>
          <th>GENERAR/ACTUALIZAR</th>
          <th>DESCARGAR</th>
          <th>EDITAR</th>
          <th>ELIMINAR</th>
        </tr>
      </thead>
      <tbody>
        {listaProfesores.length > 0 ? (
          listaProfesores.map((profesor, index) => {
            return (
              <tr key={index}>
                <td>{profesor.codigoProfesor}</td>
                <td onDoubleClick={() => handleRowDoubleClick(profesor)}>
                  {profesor.nombreProfesor}
                </td>
                <td>
                  {profesor.isEdit ? (
                    <input
                      type="text"
                      value={profesor.noOficio}
                      onChange={(e) =>
                        handleNoOficioChange(e.target.value, profesor)
                      }
                    />
                  ) : (
                    profesor.noOficio
                  )}
                </td>
                <td>{profesor.ubicacion || "No disponible"}</td>
                <td>{profesor.cicloEscolar}</td>
                <td>
                  {profesor.marcado === "1" && profesor.hasChanged && (
                    <button
                      className={`${styles.button} ${styles.buttonChanged}`}
                      onClick={() => updatePdf(profesor)}
                    >
                      Actualizar PDF
                    </button>
                  )}
                  {profesor.marcado === "0" && profesor.hasChanged && (
                    <button
                      className={styles.button}
                      onClick={() => generateNewPdf(profesor)}
                      disabled={
                        !profesor.noOficio ||
                        !profesor.ubicacion ||
                        !profesor.materia
                      }
                    >
                      Generar Nuevo PDF
                    </button>
                  )}
                </td>

                <td>
                  {profesor.marcado === "1" ? (
                    <button
                      className={`${styles.button}`}
                      onClick={() => downloadPdf(profesor)}
                    >
                      Descargar PDF
                    </button>
                  ) : (
                    <button
                      className={`${styles.button}`}
                      onClick={() => {}}
                      disabled={true}
                    >
                      No disponible
                    </button>
                  )}
                </td>
                <td>
                  <button
                    className={`${styles.button}`}
                    onClick={() =>
                      profesor.isEdit
                        ? saveEditProfesor(profesor)
                        : editProfesor(profesor)
                    }
                    disabled={!cicloDisponible}
                  >
                    {profesor.isEdit ? "Guardar" : "Editar"}
                  </button>
                </td>
                <td>
                  <button
                    className={`${styles.button} ${
                      profesor.marcado === "0" ? styles.buttonDisabled : ""
                    }`}
                    onClick={() =>
                      handleDeleteProfesor(profesor.idQr, currentPage)
                    }
                    disabled={profesor.marcado === "0" || !cicloDisponible}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan="15">
              No hay docentes disponibles para el ciclo seleccionado
            </td>
          </tr>
        )}
      </tbody>
      {modalIsOpen && (
        <MateriaModal
          profesorData={profesorData}
          setModalIsOpen={setModalIsOpen}
        />
      )}
    </table>
  );
};

export default TablaMateriasEstudianteDetalle;
