import React, { useState } from 'react';
import Modal from '../../../reutilizable/Modal';
import ViewerPDF from '../../../reutilizable/ViewerPDF';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import styles from './styles/ProcesarCriterios.module.css';

const Encabezado = ({ nivel, children }) => {
  const Tag = `h${nivel + 2}`;
  const dynamicClassName = `${styles.encabezado} ${styles[`encabezadoNivel${nivel}`]}`;

  return (
    <>
      <Tag className={dynamicClassName}>
        {children}
      </Tag>
    </>
  );
};

const ProcesarCriterios = ({ criterios, ancestroTopeVeces = null, puntaje = {} }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [urlPDF, setUrlPDF] = useState(null);
  if (!criterios || criterios.length === 0) return null;

  const openModal = () => setIsModalOpen(!isModalOpen);
  const closeModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      <div>
        <Modal
          isOpen={isModalOpen}
          onClose={closeModal}
          width='850px'
        >
          <ViewerPDF urlPdf={urlPDF} />
        </Modal>

        {criterios.map((nodo) => {
          const { criterio, subCriterios = [], nivel, nodo: urlPdf } = nodo;
          const { id, nombre, topeVeces, valorFijo } = criterio;

          const existeDocumento = urlPdf === null;
          //const isTopeVecesNull = topeVeces === null || topeVeces === undefined;

          // Si es nodo hoja
          if (subCriterios.length === 0 || !subCriterios) {
            return (
              <div key={id} className={styles.containerRespuestas}>
                <div className={styles.respuesta}>
                  <input
                    type='text'
                    name={`criterio-${id}`}
                    className={styles.inputText}
                    disabled={existeDocumento ? true : false}
                    value={puntaje[id] || '0'}
                  />
                  <label className={styles.respuestaLabel}>
                    {nombre}
                  </label>
                  <button
                    type='button'
                    className={existeDocumento ? `${styles.buttonDisabled}` : `${styles.buttonPdf}`}
                    title={existeDocumento ? 'Sin documento' : 'Ver Documento'}
                    disabled={existeDocumento ? true : false}
                    onClick={() => {
                      openModal();
                      setUrlPDF(urlPdf)
                    }}
                  >
                    <FontAwesomeIcon icon={faFilePdf} color={existeDocumento ? 'gray' : 'red'} size='2xl' />
                  </button>
                </div>

              </div>
            );
            // Caso 1: Ancestro con topeVeces = 1, imprimir radio buttons
            /* if (ancestroTopeVeces === 1) {
              return (
                <div key={id} className={styles.containerRespuestas}>
                  <div className={styles.respuesta}>
                    <input
                      type='radio'
                      name={`grupo-${criterio.idAntecesor}`}
                      value={id}
                      className='checkbox_blue_check'
                    />
                    <label className={styles.respuestaLabel}>
                      {nombre}
                    </label>
                  </div>
                </div>
              );
            } */

            // Caso 2: topeVeces = 1, imprimir checkbox
            /* if (topeVeces === 1) {
              return (
                <div key={id} className={styles.containerRespuestas}>
                  <div className={styles.respuesta}>
                    <input
                      type='checkbox'
                      name={`criterio-${id}`}
                    />
                    <label className={styles.respuestaLabel}>
                      {nombre}
                    </label>
                  </div>
                </div>
              );
            } */

            // Caso 3: topeVeces > 1, imprimir input de texto
            /* if (topeVeces > 1 || isTopeVecesNull || topeVeces === 1) {
              return (
                <div key={id} className={styles.containerRespuestas}>
                  <div className={styles.respuesta}>
                    <input
                      type='text'
                      name={`criterio-${id}`}
                      className={styles.inputText}
                    />
                    <label className={styles.respuestaLabel}>
                      {nombre}
                    </label>
                  </div>

                  <button
                    type='button'
                    className={existeDocumento ? `${styles.buttonDisabled}` : `${styles.buttonPdf}`}
                    title={existeDocumento ? 'Sin documento' : 'Ver Documento'}
                    disabled={existeDocumento ? true : false}
                    onClick={openModal}
                  >
                    <FontAwesomeIcon icon={faFilePdf} color={existeDocumento ? 'gray' : 'red'} />
                  </button>
                </div>
              );
            } */
          } else {
            // Si no es nodo hoja, procesar los subCriterios
            return (
              <div key={id} className={styles.formPregunta}>
                <Encabezado
                  nivel={nivel}
                  criterio={criterio}
                >{nombre}</Encabezado>
                <ProcesarCriterios criterios={subCriterios} ancestroTopeVeces={topeVeces} puntaje={puntaje} />
              </div>
            );
          }

          //return null; // Por seguridad
        })}
      </div>
    </>
  );
};

export default ProcesarCriterios;