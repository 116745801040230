import ReactDOM from 'react-dom';
import styles from './styles/Modal.module.css';

/**
 * Modal component
 * 
 * @param {Object} props
 * @param {string} props.title - Título del modal
 * @param {React.ReactNode} props.children - Elemento HTMl que sera el contenido del modal
 * @param {boolean} props.isOpen - Propiedad para saber si el modal es visible
 * @param {function(): void} props.onClose - Función para abrir o cerrar el componente
 * @param {string} props.style - Propiedad del ancho para el modal
 */
const Modal = ({ title = '', children, isOpen, onClose, width = '700px'}) => {

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modal} style={{ width }}>
        {/* Header */}
        <div className={styles.header}>
          <h2 className={styles.headerTitle}>{title}</h2>
          <button onClick={onClose} className={styles.closeButton}>&times;</button>
        </div>

        {/* Content */}
        <div className={styles.content}>
          {children}
        </div>

        {/* Footer */}
        <div className={styles.footer}>
          <button 
            onClick={onClose}
            className={styles.cancelButton}
          >Cancelar</button>
          <button
            onClick={onClose}
            className={styles.okButton}
          >OK</button>
        </div>
        
      </div>
    </div>,
    document.body
  );
};

export default Modal;
