import { useState } from "react";
import { saveBreaches } from "../services/saveBreacher";

const initialModalState = {
  state: false,
  action: "",
};

const initialActionBreach = {
  message: "",
  loading: false,
  response: null,
};

export const useInfoRight = () => {
  const [modalOpen, setModalOpen] = useState(initialModalState);
  const [breaches, setBreaches] = useState([]);
  const [actionBreach, setActionBreach] = useState(initialActionBreach);
  const [breachesToDelete, setBreachesToDelete] = useState([]);

  const onCloseModal = () => {
    setModalOpen(initialModalState);
  };

  const onOpenModal = (action) => {
    setModalOpen({
      state: true,
      action,
    });
  };

  const addBreach = () => {
    setBreaches([...breaches, {}]);
  };

  const cleanBreaches = () => {
    setBreaches([]);
  };

  //? Método para eliminar un incumplimiento
  const onDeleteBreach = async () => {
    setActionBreach({
      ...actionBreach,
      loading: false,
    });
    try {
      const res = await onDeleteBreach();

      setActionBreach({
        ...actionBreach,
        message: res.message,
        response: res.result,
        loading: false,
      });
    } catch (error) {
      setActionBreach({
        ...actionBreach,
        message: error.message,
        loading: false,
      });
    }
  };

  //? Método para guardar un incumplimiento
  const onSaveBreach = async () => {
    setActionBreach({
      ...actionBreach,
      loading: false,
    });
    try {
      const res = await saveBreaches();

      setActionBreach({
        ...actionBreach,
        message: res.message,
        response: res.result,
        loading: false,
      });
    } catch (error) {
      setActionBreach({
        ...actionBreach,
        message: error.message,
        loading: false,
      });
    }
  };

  const handleAddBreachToDelete = (breach) => {
    setBreachesToDelete([...breachesToDelete, breach]);
  };

  return {
    // properties
    modalOpen,
    breaches,
    actionBreach,
    // methods
    onCloseModal,
    onOpenModal,
    addBreach,
    cleanBreaches,
    onDeleteBreach,
    onSaveBreach,
    handleAddBreachToDelete,
  };
};
