import React, { useCallback, useState } from "react";
import api from "../../../../hooks/api";

const useFetchProfesoresList = () => {
    const [profesoresList, setProfesoresList] = useState([]);
    const [isProfesoresLoading, setIsProfesoresLoading] = useState(true);


  const getProfesoresList = useCallback(async (data) => {

    const body = {
      codigoEmpleado: data.codigoEmpleado,
      cicloEscolar: data.cicloEscolar,
      page: data.page,
      app: "PruebasSpertoDigital",
    };
    try {
        console.log(body);
      const response = await api.post(
        "/api/v1/jefe_depto/lista-profesores-oficio-cumplimiento-plan-trabajo",
        body
      );
      console.log(response.data);
      setProfesoresList(response.data);
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
        setIsProfesoresLoading(false);
    }
  }, [])
  return {
    profesoresList,
    isProfesoresLoading,
    getProfesoresList,
  }
};

export default useFetchProfesoresList;
