import axios from "axios";
import React, { useState } from "react";
import api from "../../../../hooks/api";

const useFetchGetListProfesores = () => {
  const [listaProfesores, setListaProfesores] = useState([]);
  const [isLoadingProfesores, setIsLoadingProfesores] = useState(false);

  const getListProfesores = async (body) => {
    try {
      setIsLoadingProfesores(true);
      const response = await api.post(
        "/api/v1/jefe_depto/lista-profesores-oficio-evaluacion-estudiante",
        body
      );
      setTimeout(() => {
        setListaProfesores(
          response.data.map((profesor) => ({
            ...profesor,
            isEdit: false,
            hasChanged: false,
          }))
        );
        setIsLoadingProfesores(false);
      }, 700);
    } catch (error) {
      console.log(error);
    }
  };
  return {
    getListProfesores,
    isLoadingProfesores,
    listaProfesores,
    setListaProfesores,
  };
};

export default useFetchGetListProfesores;
