import axios from "axios";
import { getAccessToken } from "../../../authService"; // Asumimos que tienes el servicio de autenticación separado
import {
  requestAltasPlanTrabajo,
  requestUpdatePlanTrabajo,
} from "../adapters/PlanTrabajo.adapter";
import { instanceAxios } from "./instanceAxios";

const BASEURL_PT = "/api/v1/plan_trabajo/reporte";

export const consultaPlanTrabajoCero = async () => {
  try {
    const token = await getAccessToken();
    const response = await instanceAxios.get(
      `${BASEURL_PT}/get-cero-plan-trabajo`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error comunícate con el administrador");
  }
};

export const getPlanTrabajo = async () => {
  try {
    const token = await getAccessToken();
    const response = await instanceAxios.get(`${BASEURL_PT}/get-plan-trabajo`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error("Error al obtener el plan de trabajo");
  }
};

export const altaPlanTrabajo = async (dataAcademic) => {
  try {
    const token = await getAccessToken();
    const response = await instanceAxios.post(
      `${BASEURL_PT}/alta-plan-trabajo`,
      requestAltasPlanTrabajo(dataAcademic),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return true;
    }

    throw new Error("Error al guardar el plan de trabajo");
  } catch (error) {
    console.log(error);
    throw new Error("Error al guardar el plan de trabajo");
  }
};

export const updatePlanTrabajo = async (dataAcademic) => {
  try {
    const token = await getAccessToken();
    const response = await instanceAxios.post(
      `${BASEURL_PT}/editar-plan-trabajo`,
      requestUpdatePlanTrabajo(dataAcademic),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.status === 200) {
      return true;  
    }

    throw new Error("Error al guardar el plan de trabajo");
  } catch (error) {
    console.log(error);
    throw new Error("Error al guardar el plan de trabajo");
  }
};

export const getPDF = async (id) => {
  try {
    const token = await getAccessToken();
    const response = await axios.post(
      `${BASEURL_PT}/consulta-plan-trabajo?id=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      }
    );

    if (response.status === 200) {
      return response.data;
    }

    throw new Error("Error al descargar el PDF");
  } catch (error) {
    console.error("Error al obtener el PDF:", error.message);
    throw new Error("Error al guardar el plan de trabajo");
  }
};
