import axios from "axios";

export const getInfoAcademico = async () => {
  try {
    const response = await axios.post("");
    if (response.status === 200) {
      return response.data;
    }

    throw new Error("Academico no encontrado");
  } catch (error) {
    throw new Error("Ocurrió un error al buscar los Academico");
  }
};
