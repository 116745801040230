import React, { useEffect, useState } from "react";
import { Footer, Header } from "../../layout";
import styles from "./styles/AdminisstracionPage.module.css";
import { Menu, useMenu } from "../../../reutilizable/Menu";
import { useAuthStore } from "../../../store/useAuthStore";
import { ActivarInactivarSolicitud } from "../components/ActivarInactivarSolicitud";
import { Instrucciones } from "../../lista-miembros/components/Instrucciones";
import ReporteResultadosEvaluacion from "../../reportes/components/ReporteResultadosEvaluacion";
import ReporteActaAcuerdos from "../../reportes/components/ReporteActaAcuerdos";
import ReporteActaEvaluaciones from "../../reportes/components/ReporteActaEvaluaciones";
import ReporteDictamenNoParticipantes from "../../reportes/components/ReporteDictamenNoParticipantes";
import ReporteDictamenFinal from "../../reportes/components/ReporteDictamenFinal";
import Inconformidad from "../../inconformidad/components/Inconformidad";
import { GenerarInconformidad } from "../components/GenerarInconformidad";
import Evaluacion from "../../evaluacion/components/Evaluacion";
import ReporteEstadistico from "../../reportes/components/ReporteEstadisticos";
import ReporteActaSesion from "../../reportes/components/ReporteActaSesion";
import { ReimprimirSolicitud } from "../components/ReimprimirSolicitud";
import axios from "axios";
import TablaResultadosEvaluacion from "../../evaluacion/components/TablaResultadosEvaluacion";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_URL_SUBMENUS = `${API_BASE_URL}/api/v1/actividad/comision_dictaminadora_cu_ep`;

export const AdministracionPage = () => {
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);
  const [menuResponse, setMenuResponse] = useState([])

  const componentMap = {
    "Imprimir acta de evaluaciones": <ReporteActaEvaluaciones />,
    "Tabla de evaluación de resultados": <TablaResultadosEvaluacion />,
    "Imprimir acta de acuerdos": <ReporteActaSesion />,
    "Dictamen de no participante": <ReporteDictamenNoParticipantes />,
    "Dictamen final": <ReporteDictamenFinal />,
    "Imprimir acta de asignación de nivel": <ReporteActaAcuerdos />,
    "Evaluación": <Evaluacion />,


  };

  const accessToken = useAuthStore((state) => state.accessToken);
  const refreshToken = useAuthStore((state) => state.refreshToken);
  const userInfo = useAuthStore((state) => state.userInfo);

  const handleVaciadosExcelClick = () => {
    alert("Descargando vaciados excel...");
  };
  const handleVaciadosExceGeneralClick = () => {
    alert("Descargando vaciados excel general...");
  };
  const handleVaciadosExcelTPartClick = () => {
    alert("Descargando vaciados excel (T. Part)...");
  };
  const handlePublicacionGacetaClick = () => {
    alert("Descargando publicacion gaceta...");
  };

  const handleActaDeAcuerdosClick = () => {
    alert("Descargando acta de acuerdos...");
  };


  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.get(API_URL_SUBMENUS, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const sortedMenus = (response.data.menus || []).map((menu) => ({
          ...menu,
          submenus: Array.isArray(menu.submenus)
            ? menu.submenus
              .map((submenu) => ({
                ...submenu,
              }))
              .sort((a, b) => (a.orden === b.orden ? a.id - b.id : a.orden - b.orden))
            : [], // Handle missing or invalid `submenus`
        })).sort((a, b) => (a.orden === b.orden ? a.id - b.id : a.orden - b.orden));

        setMenuResponse(sortedMenus);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchMenuData();
  }, []);

  const menu = useMenu(
    menuResponse.map((menu) => ({
      label: menu.nombreCorto,
      ...(menu.submenus.length > 0
        ? {
          children: menu.submenus.map((submenu) => ({
            label: submenu.nombreCorto,
            element: componentMap[submenu.nombreCorto] || <p>{submenu.nombreCorto}</p>,
          })),
        }
        : {
          element: componentMap[menu.nombreCorto] || <p>{menu.nombreCorto}</p>,
        }),
    })),
    { selectedValue: "Instrucciones", isVertical: false }
  );


  return (
    <>
      <Header />
      <div className={styles.container}>
        <Menu menu={menu} />

        {menu.element}
      </div>

      <Footer />
    </>
  );
};

/*
  const menu = useMenu(
    [
      {
        label: "Instrucciones",
        element: (
          <>
            <Instrucciones />,
          </>
        ),
      },
      {
        label: "Inconformidad",
        element: <Inconformidad />,
      },
      {
        label: "Evaluación",
        element: <Evaluacion />,
      },
      {
        label: "Reportes",
        children: [
          {
            label: "Imprimir acta de instalación",
            element: <ReporteActaAcuerdos />,
          },
          {
            label: "Imprimir acta de evaluaciones",
            element: <ReporteActaEvaluaciones />,
          },
          {
            label: "Imprimir acta de acuerdos",
            element: <ReporteActaSesion />,
          },
          {
            label: "Tabla de resultados de evaluacion",
            element: <ReporteResultadosEvaluacion />,
          },
          {
            label: "Dictamen de no participante",
            element: <ReporteDictamenNoParticipantes />,
          },
          {
            label: "Dictamen final",
            element: <ReporteDictamenFinal />,
          },
          {
            label: "Reporte estadisticos",
            element: <ReimprimirSolicitud />,
          },
        ],
      },
      {
        label: "Vaciados Excel",
        children: [
          {
            label: "Imprimir vaciado a excel",
            onClick: () => handleVaciadosExcelClick(),
          },
          {
            label: "Publicación en Gaceta",
            onClick: () => handlePublicacionGacetaClick(),
          },
          {
            label: "Vaciado a excel (T. Part.)",
            onClick: () => handleVaciadosExcelTPartClick(),
          },
          {
            label: "Vaciado a excel general",
            onClick: () => handleVaciadosExceGeneralClick(),
          },
        ],
      },
      {
        label: "Generar Inconformidad",
        element: <GenerarInconformidad />,
      },
      {
        label: "Administración",
        element: <p>Contenido de la Administración</p>,
        children: [
          {
            label: "Comisiones",
            element: <p>Comisiones</p>,
          },
          {
            label: "Estadísticas",
            element: <p>Estadisticas</p>,
          },
          {
            label: "Modificar usuario",
            element: <p>Modificar usuario</p>,
          },
          {
            label: "Nuevo usuario",
            element: <p>Nuevo usuario</p>,
          },
          {
            label: "Incumplimiento de académico",
            element: <p>Incumplimiento de académico</p>,
          },
          {
            label: "Editar solicitud",
            element: <p>Editar solicitud</p>,
          },
          {
            label: "Activar / inactivar solicitud",
            element: <ActivarInactivarSolicitud />,
          },
          {
            label: "Reimprimir Solicitud",
            element: <ReimprimirSolicitud />,
          },
          
        ],
      },
      {
        label: "Publicaciones",
        element: <p>Publicaciones</p>,
      },
    ],
    {
      selectedValue: "Desempeño docente",
      isVertical: false,
    }
  );*/