import React, { useEffect, useState } from "react";
import axios from "axios";
import { Menu, useMenu } from "../../../../reutilizable/Menu";
import { Footer, Header } from "../../../layout";
import { Instrucciones } from "../../instrucciones/components/Instrucciones";
import { LlenarSolicitudComponent } from "../components/LlenarSolicitudComponent";
import ArmarExpedientePage from '../../expediente/pages/ArmarExpedientePage';
import ExpedienteCriteriosPage from "../../expediente/pages/ExpedienteCriteriosPage";
import ExpedienteRequisitosPage from "../../expediente/pages/ExpedienteRequisitosPage";
import { VisualizarSolicitud } from "../../visualizarSolicitud/components/visualizarSolicitud";
import { ModificarSolicitud } from "../../modificarSolicitud/components/ModificarSolicitud";
import PlanTrabajoPage from "../../planTrabajo/pages/planTrabajoPage";
import styles from "./llenarSolicitud.module.css";
import { GenerarInconformidad } from "../../../administracion/components/GenerarInconformidad";

// Obtener la URL base desde las variables de entorno 
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_URL_SUBMENUS = `${API_BASE_URL}/api/v1/actividad/academico`;

export const LlenarSolicitudPage = () => {
  const [menuResponse, setMenuResponse] = useState([]);
  const [status, setStatus] = useState(null);
  const API_URL_STATUS = `${API_BASE_URL}/api/v1/solicitud/status`;

  const componentMap = {
    "Llenar solicitud": <LlenarSolicitudComponent />,
    "Instrucciones": <Instrucciones />,
    "Modificar solicitud": <ModificarSolicitud />,
    "Consultar datos": <VisualizarSolicitud />,
    "Expediente": <ArmarExpedientePage />,
    "Requisitos": <ExpedienteRequisitosPage />,
    "Criterios": <ExpedienteCriteriosPage />,
    "Plan de trabajo": <PlanTrabajoPage />,
    "Generar Inconformidad": <GenerarInconformidad />,
  };

  // Obtener el estado
  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    axios
      .get(API_URL_STATUS, {
        headers: {
          Accept: "*/*",
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("status", response.data);
        setStatus(parseInt(response.data));
      }).catch((error) => console.error("Error fetching status: ", error));
  }, );

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const token = localStorage.getItem("accessToken");
        const response = await axios.get(API_URL_SUBMENUS, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const sortedMenus = (response.data.menus || [])
          .map((menu) => ({
            ...menu,
            submenus: Array.isArray(menu.submenus)
              ? menu.submenus
                  .map((submenu) => ({
                    ...submenu,
                  }))
                  .sort((a, b) => (a.orden === b.orden ? a.id - b.id : a.orden - b.orden))
              : [], // Handle missing or invalid `submenus`
          }))
          .sort((a, b) => (a.orden === b.orden ? a.id - b.id : a.orden - b.orden));

        setMenuResponse(sortedMenus);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchMenuData();
  }, []);

  const filteredMenuResponse = menuResponse.filter(
    (menu) => (status !== 0 && status !== 1) || menu.nombreCorto !== "Expediente"
  );

  const menu = useMenu(
    filteredMenuResponse.map((menu) => ({
      label: menu.nombreCorto,
      ...(menu.submenus.length > 0
        ? {
            children: menu.submenus.map((submenu) => ({
              label: submenu.nombreCorto,
              element: componentMap[submenu.nombreCorto] || <p>{submenu.nombreCorto}</p>,
            })),
          }
        : {
            element: componentMap[menu.nombreCorto] || <p>{menu.nombreCorto}</p>,
          }),
    })),
    { selectedValue: "Instrucciones", isVertical: false }
  );

  return (
    <>
      <div>
        <Header />
        <Menu menu={menu} />
        {menu?.element}
        <Footer />
      </div>
    </>
  );
};
