import React, { useState } from "react";
import styles from "../styles/TablaPlanTrabajoDetalles.module.css";
import ModalValidacionPlanTrabajo from "./ModalValidacionPlanTrabajo";
import useListPlanTrabajo from "../hooks/useListPlanTrabajo";


const TablaPlanTrabajoDetalles = ({
  currentItems,
  municipios,
  cicloDisponible,
  getSortIcon,
  handleSort,
  userInfo,
}) => {
  const { getDetallePlanTrabajo } = useListPlanTrabajo();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState({});


  const handleRowClick = async (item) => {
    console.log(item)
    const response = await getDetallePlanTrabajo(item.noOficio, item.codigo);
    console.log(response)
    
    setSelectedData(item);
    setModalOpen(true);
  };

  const getMunicipioName = (ubicacion) => {
    if (!ubicacion) return "No disponible";
    const municipio = municipios.find((m) => m.id === Number(ubicacion));
    return municipio ? municipio.municipio : "No disponible";
  };

  return (
    <>
      <table className={styles.tablePlan}>
        <thead>
          <tr>
            <th rowSpan="2" onClick={() => handleSort("codigoProfesor")}>
              CÓDIGO PROFESOR {getSortIcon("codigoProfesor")}
            </th>
            <th rowSpan="2" onClick={() => handleSort("nombreProfesor")}>
              NOMBRE DOCENTE {getSortIcon("nombreProfesor")}
            </th>
            <th rowSpan="2">NO. SOLICITUD</th>
            <th rowSpan="2">ESTATUS</th>
            <th rowSpan="2">MUNICIPIO</th>
            <th rowSpan="2">CICLO ESCOLAR</th>
            <th>GENERAR / ACTUALIZAR</th>
            <th>DESCARGAR</th>
            <th>ELIMINAR</th>
          </tr>

        </thead>
        <tbody>
          {currentItems.length > 0 ? (
            currentItems.map((item, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(item)}
                className={styles.clickableRow}
                style={{ cursor: "pointer" }}
              >
                <td>{item.codigo}</td>
                <td>{item.nombreDocente}</td>
                <td>{item.noOficio || "N/A"}</td>
                <td
                  className={
                    item.estatus === "Aprobado"
                      ? styles.statusApproved
                      : item.estatus === "Rechazado"
                        ? styles.statusRejected
                        : styles.statusPending
                  }
                >
                  {item.estatus}
                </td>
                <td>{getMunicipioName(item.ubicacion)}</td>
                <td>{item.cicloEscolar}</td>
                <td>
                  <button
                    className={styles.button}
                    disabled={!cicloDisponible}
                    onClick={(e) => {
                      e.stopPropagation();
                      alert("Generar/Actualizar PDF");
                    }}
                  >
                    {item.noOficio ? "Actualizar PDF" : "Generar PDF"}
                  </button>
                </td>
                <td>
                  <button
                    className={styles.button}
                    disabled={!item.noOficio}
                    onClick={(e) => {
                      e.stopPropagation();
                      alert("Descargar PDF");
                    }}
                  >
                    {item.noOficio ? "Descargar PDF" : "No disponible"}
                  </button>
                </td>
                <td>
                  <button
                    className={styles.button}
                    disabled={!item.noOficio}
                    onClick={(e) => {
                      e.stopPropagation();
                      alert("Eliminar");
                    }}
                  >
                    Eliminar
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9">No hay datos disponibles para el ciclo seleccionado</td>
            </tr>
          )}
        </tbody>
      </table>
      {modalOpen && (
        <ModalValidacionPlanTrabajo
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          data={selectedData}
          userInfo={userInfo}
        />
      )}
    </>
  );
};

export default TablaPlanTrabajoDetalles;
