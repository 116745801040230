import React from 'react'
import useMunicipios from '../../../../hooks/useMunicipios';
import useCumplimientoTrabajoContext from '../context/useCumplimientoTrabajoContext';
import styles from '../styles/FiltroCargaCumplimientoTrabajo.module.css';

function FiltroCargaCumplimientoTrabajo() {
    const { currentCiclo, selectedMunicipio, } = useCumplimientoTrabajoContext();
  const { municipios } = useMunicipios();

    const handleMunicipioChange = (municipio) => {
        console.log(municipio);
    }
  return (
    <div className={styles.searchAndMunicipio}>
      <div className={styles.municipioFilterContainer}>
        <label htmlFor="municipioSelector">Municipio: </label>
        <select
          id="municipioSelector"
          value={selectedMunicipio}
          onChange={handleMunicipioChange}
          disabled={!currentCiclo}
        >
          <option value="">Seleccione un municipio</option>
          {municipios.map((municipio) => (
            <option key={municipio.id} value={municipio.id}>
              {municipio.municipio}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default FiltroCargaCumplimientoTrabajo
