import React, { useState, useEffect } from "react";
import { login } from "../../../../features/authService";
import styles from "./login.styles.module.css";
import { useNavigate } from "react-router-dom";
import RoleSelectionModal from "../../../../reutilizable/RoleSelectionModal";

export const LoginComponent = () => {
  const [usuario, setUsuario] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [error, setError] = useState("");
  const [showRoleModal, setShowRoleModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(roles)

    if (!usuario || !fechaNacimiento) {
      setError("Todos los campos son obligatorios.");
    } else {
      try {
        // Llamar a la función de login y obtener roles
        const { rolesAsArray } = await login(usuario, fechaNacimiento);
        setError("");

        if (rolesAsArray.length > 1) {
          setRoles(rolesAsArray);
          setShowRoleModal(true);
        } else {
          handleRedirection(rolesAsArray[0]);
        }
      } catch (error) {
        setError("Login fallido. Verifique sus credenciales.");
      }
    }
  };

  // Función para manejar la redirección basada en el rol seleccionado
  const handleRedirection = (selectedRole) => {
    console.log("Redirigiendo para el rol:", selectedRole);

    console.log("rol ", selectedRole.toLowerCase())
    switch (selectedRole.toLowerCase()) {
      case "academico":
        console.log("Navegando a /llenar-solicitud");
        navigate("/llenar-solicitud");
        break;
      case "admin_gral":
        console.log("Navegando a /administracion");
        navigate("/administracion");
        break;
      case "comision_dictaminadora_cu_ep":
        console.log("Navegando a /reportes");
        navigate("/administracion");
        break;
      case "jefe_depto":
        console.log("Navegando a /jefe_departamento");
        navigate("/jefe_departamento");
        break;
      case "admin_convocatoria":
        console.log("Navegando a /administrador-convocatoria");
        navigate("/administrador-convocatoria");
        break;
      case "secretaria_escuelas_preparatorias":
        console.log("Navegando a /secretaria_escuelas_preparatorias");
        navigate("/secretaria_escuelas_preparatorias");
        break;
      case "secretaria_admin_cu":
        console.log("Navegando a /secretaria_admin_cu");
        navigate("/secretaria_admin_cu");
        break;
      case "secretaria_admin_sems":
        console.log("Navegando a /secretaria_admin_sems");
        navigate("/secretaria_admin_sems");
        break;
      case "comision_especial_dictaminadora_ag":
        console.log("Navegando a /comision_especial_dictaminadora_ag");
        navigate("/comision_especial_dictaminadora_ag");
        break;
      case "comision_especial_dictaminadora_sems":
        console.log("Navegando a /comision_especial_dictaminadora_sems");
        navigate("/comision_especial_dictaminadora_sems");
        break;

      default:
        console.log("Navegando a /default-dashboard");
        navigate("/default-dashboard");
        break;
    }
  };

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError(""); // Limpiar el error después de 3 segundos
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [error]);

  return (
    <div>
      <div className={styles.main}>
        <p className={styles.text}>
          Bienvenido al Programa de
          <br />
          <span className={styles.fontxl}>
            Estímulos al Desempeño Docente (PROESDE) 2024-2025
          </span>
        </p>

        <div className={styles.login_container_head}>
          <span className={styles.textlogin}>
            Para ingresar al sistema por favor proporcione su usuario y contraseña
          </span>
        </div>

        <div className={styles.login_container}>
          <form onSubmit={handleSubmit}>
            <div className={styles["form-group"]}>
              <label htmlFor="usuario" className={styles.label}>
                USUARIO / CÓDIGO
              </label>
              <div className={styles.formplace}>
                <input
                  id="usuario"
                  type="text"
                  className={styles.input}
                  value={usuario}
                  onChange={(e) => setUsuario(e.target.value)}
                />
                <span className={styles.placeholder}>(Su código, si es académico)</span>
              </div>
            </div>
            <div className={styles["form-group"]}>
              <label htmlFor="fecha_nacimiento" className={styles.label}>
                CONTRASEÑA
              </label>
              <div className={styles.formplace}>
                <input
                  id="fecha_nacimiento"
                  type="password"
                  className={styles.input}
                  value={fechaNacimiento}
                  onChange={(e) => setFechaNacimiento(e.target.value)}
                  placeholder=""
                />
                <span className={styles.placeholder}>(Contraseña)</span>
              </div>
            </div>
            <div className={styles["button-container"]}>
              <button className={styles.button}>Ingresar</button>
            </div>

            {error && <p className={styles.error}>{error}</p>}
          </form>
        </div>

        <div className={styles.infoSection}>
          <p className={styles.infoText}>
            Si desea consultar la convocatoria, tabla de puntaje y reglamento del PROESDE,<br />
            puede hacerlo mediante el portal de la CGAI:
          </p>
          <button className={styles.infoButton}>
            Coordinación General Académica y de Innovación
          </button>
        </div>

        <div className={styles.contactSection}>
          <p className={styles.contactInfo}>
            Si desea obtener mayor información acerca de la convocatoria contacte al<br />
            teléfono <strong>333134-2200 extensión 11966</strong> en un horario de <strong>10:00 a 18:00 hrs</strong>.
          </p>

          <p className={styles.contactInfo}>
            En caso de tener algún error en el sistema contacte por medio del <br />
            correo electrónico a <a href="mailto:proesde@udg.mx">proesde@udg.mx</a>.
          </p>
        </div>

        <div className={styles.browserCompatibility}>
          <p>NO soporta Internet Explorer, Microsoft Edge y Safari.</p>
          <p>Este sitio se visualiza mejor con Firefox o Google Chrome</p>
        </div>
      </div>

      <div>
        <RoleSelectionModal
          open={showRoleModal}
          roles={roles}
          onClose={() => setShowRoleModal(false)}
          onConfirmRole={(selectedRole) => {
            setShowRoleModal(false);
            handleRedirection(selectedRole);
          }}
        />
      </div>
    </div>
  );
};
