import React, { useState, useEffect, useCallback } from "react";
import Loading from "../../../../../reutilizable/Loading";

import TablaInconformidad from "../component/TablaInconformidad";
import styles from "./DocInconformidad.module.css";

const DocInconformidad = () => {
  const [isLoading, setIsLoading] = useState();
  const [filters, setFilters] = useState({
    searchQuery: '',
  });
  const [datosInconformidad, setDatosInconform] = useState([]);

  const exampleData = [
    {
      id: "97FQ2F80HAF2TWG09WU0WNB0A8G",
      idSolicitud: "109",
      codigoProfesor: '9305785',
      nombreProfesor: 'Emmanuel',
      dependencia: 'Facultad de Ingeniería',
      probatorio: '1',
      marcado: '1',
    },
    {
      id: "97FQ2F80HAF228035u019tB0A8G",
      codigoProfesor: '9049785',
      idSolicitud: "119",
      nombreProfesor: 'Emmanuel Jesus',
      dependencia: 'Facultad de Ingeniería',
      probatorio: '1',
      marcado: '0',
    },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  }

  const handleSearch = () => {
    console.log('Search Query:', filters.searchQuery);

    //llamar api para filtrar y obtener informacion
    setIsLoading(true);
    // Simulacion de llamada a API
    setTimeout(() => {
      setDatosInconform(exampleData);
      setIsLoading(false);
      console.log("Disparo buscar: ", datosInconformidad)
    }, 1000);
  };

  return (
    <div className={styles.container}>
      <article>
        <header>
          <h3 className={styles.inconformidad_title}>
            Generar documento de inconformidad
          </h3>
        </header>

        <div className={styles.filterContainer}>
          {/* <div className={styles.searchInputContainer}>
            <label htmlFor="searchInput" className={styles.searchLabel}>Buscar: </label>
            <input
              type="text"
              id="searchInput"
              className={styles.searchInput}
              placeholder="Buscar por código de profesor o nombre"
              name="searchQuery"
              value={filters.searchQuery}
              onChange={handleInputChange}    
            />
          </div> */}

        </div>

        <div className={styles.submit}>
          <button className={styles.btn} onClick={handleSearch}>
            Buscar
          </button>
        </div>

        {isLoading ? (
          <div>
            <Loading />
          </div>
        ) : (
          
            <TablaInconformidad
              currentItems={datosInconformidad}
            />
          
        )}

      </article>
    </div>
  );
};

export default DocInconformidad;
